import React from "react";
import {
  Box,
  Drawer,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup
} from "@mui/material";

interface FilterSidebarProps {
  mobileOpen: boolean;
  setMobileOpen: (status: boolean) => void;
}

const FilterSidebar: React.FC<FilterSidebarProps> = (props) => {
  const { mobileOpen, setMobileOpen } = props;

  const drawerContent = (
    <Box sx={{ width: "100%", p: "2em" }}>
      <Typography variant="h6" gutterBottom>
        Filters
      </Typography>

      <Typography variant="subtitle1" sx={{ mt: 2 }}>
        Job Type
      </Typography>
      <FormGroup>
        <FormControlLabel control={<Checkbox />} label="Full-Time" />
        <FormControlLabel control={<Checkbox />} label="Part-Time" />
        <FormControlLabel control={<Checkbox />} label="Internship" />
      </FormGroup>

      <Typography variant="subtitle1" sx={{ mt: 2 }}>
        Experience
      </Typography>
      <FormGroup>
        <FormControlLabel control={<Checkbox />} label="1-3 Years" />
        <FormControlLabel control={<Checkbox />} label="3-5 Years" />
      </FormGroup>
    </Box>
  );

  return (
    <>
      {/* Flyout Drawer */}
      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={() => setMobileOpen(false)}
        variant="temporary"
        ModalProps={{
          keepMounted: true // Better open performance on mobile
        }}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%"
          }
        }}
      >
        {drawerContent}
      </Drawer>

      {/* Permanent Sidebar for Desktop */}
      <Box
        sx={{
          display: { xs: "none", md: "block" },
          width: 300,
          height: "100%"
        }}
      >
        {drawerContent}
      </Box>
    </>
  );
};

export default FilterSidebar;
