import React, { FC } from "react";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import PageHeading from "../Common/PageHeading/PageHeading";
import PageContent from "../Common/PageContent/PageContent";
import ServiceChatBookingForm from "../OurServices/ServiceChatBookingForm";

export const CareerConnect: FC = () => {
  const theme = useTheme();
  const matchMD = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <section>
      <PageHeading titleText="Connect with professional around the world" />
      <PageContent styles={{ background: "white", paddingBottom: "2em" }}>
        <Typography variant="body2" gutterBottom style={{ margin: "3em 0px" }}>
          At Kivunova, we believe in bridging the gap between education and the
          professional world. Our Career Connect program is designed to help
          students, graduates, and aspiring engineers make meaningful
          connections with leading industry professionals worldwide. Whether you
          are looking to find a mentor, secure an internship, or kickstart your
          career in tech, we are here to support you at every step. By
          partnering with top organizations, we ensure our students are exposed
          to real-world opportunities and get the chance to learn directly from
          experts in their chosen fields. Through our mentorship programs,
          workshops, and networking events, you will develop the skills and
          industry knowledge necessary to succeed in today’s competitive job
          market. Our goal is to provide you with the resources, guidance, and
          connections that can help you achieve your career aspirations, no
          matter where you are on your journey.
        </Typography>
        <Container
          sx={{
            width: "90%",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <Typography
            variant={matchMD ? "subtitle1" : "h6"}
            textAlign="center"
            fontWeight="bold"
          >
            Connect with us today!
          </Typography>
          <ServiceChatBookingForm />
        </Container>
      </PageContent>
    </section>
  );
};

export default CareerConnect;
