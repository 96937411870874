import React, { FC } from "react";
import { Container } from "@mui/material";
import NavigationItem from "./NavigationItem";
import Accordion from "../Common/Accordion/Accordion";
import { NavigationConfig } from "./types/navigationConfigType";

interface SideNavigationMenuProps {
  closeMenu: () => void;
  navigationConfigList: NavigationConfig[];
}

const SideNavigationMenu: FC<SideNavigationMenuProps> = (props) => {
  const { closeMenu, navigationConfigList } = props;

  return (
    <Container
      sx={{
        width: "100%",
        padding: "0px",
        background: "white",
        opacity: 1
      }}
    >
      {navigationConfigList.map((navConfig) =>
        navConfig.subLinks ? (
          <div style={{ borderBottom: "1px solid #f6f6f6" }}>
            <Accordion title={navConfig.title}>
              {navConfig.subLinks.map((link) => (
                <NavigationItem navConfig={link} closeMenu={closeMenu} />
              ))}
            </Accordion>
          </div>
        ) : (
          <NavigationItem navConfig={navConfig} closeMenu={closeMenu} />
        )
      )}
    </Container>
  );
};

export default SideNavigationMenu;
