import React, { FC, useContext } from "react";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Navigation from "./Navigation";
import DarkLogo from "../../Assets/images/kivu-nova-dark.png";
import LightLogo from "../../Assets/images/Kivu-Nova-ligh.jpg";
import "./styles/header.scss";
import PageThemeContext from "../../Contexts/pageThemeContext";
import { THEME } from "../../theme";
import { HOMEPAGE } from "../pageRoutes";

// eslint-disable-next-line @typescript-eslint/no-require-imports
const classNames = require("classnames");

interface HeaderProps {
  hideNavLinks?: boolean;
}

const Header: FC<HeaderProps> = (props) => {
  const { hideNavLinks } = props;

  const theme = useContext(PageThemeContext);
  const pageContainerClass = classNames("pageContainer", `page-theme-${theme}`);

  const navigate = useNavigate();
  return (
    <Container className={pageContainerClass} id="headerContainer">
      <Container
        className="headerLogoContainer"
        onClick={() => navigate(HOMEPAGE)}
      >
        <img
          src={theme === THEME.DARK ? LightLogo : DarkLogo}
          alt="Logo"
          width="100%"
          height="100%"
        />
      </Container>
      {!hideNavLinks && <Navigation />}
    </Container>
  );
};

export default Header;
