import React, { ChangeEvent, useState } from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { useMutation } from "@tanstack/react-query";
import PageContent from "../Common/PageContent/PageContent";
import {
  CreateApplicationResponse,
  CreateApplicationVariable
} from "../../Graphql/types/application";
import { createApplicationQuery } from "../../Graphql/querries/applicationQuery";
import { fetchGraphQL, uploadFileGraphQL } from "../../Graphql/utils";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1
});

const JobDetail = () => {
  const [file, setFile] = useState<File | null>(null);

  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    setFile(event.target.files?.[0] || null);
  };

  const { mutate } = useMutation<
    CreateApplicationResponse,
    Error,
    CreateApplicationVariable
  >({
    mutationFn: (data) =>
      file
        ? uploadFileGraphQL(createApplicationQuery, { input: data }, file)
        : fetchGraphQL(createApplicationQuery, { input: data }),
    mutationKey: ["createApplication"]
  });

  const handleSubmit = () => {
    if (file) {
      mutate({
        jobId: "6752801fa6ef3117c147fd03",
        memberId: "6721b4e9288764519b4f9322"
      });
    }
  };

  return (
    <section>
      <PageContent styles={{ background: "white", paddingBottom: "2em" }}>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          Upload files
          <VisuallyHiddenInput
            type="file"
            onChange={(event) => handleUploadFile(event)}
            multiple
          />
        </Button>

        <Button onClick={handleSubmit}>Submit</Button>
      </PageContent>
    </section>
  );
};

export default JobDetail;
