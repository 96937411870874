import { GraphQLResponse } from "./types/common";
import { getLocalStorageElement } from "../Utils/localStorageUtils";
import { LOCAL_STORAGE_KEYS, UNKNOWN } from "../Enums/localStorage";

export const fetchGraphQL = async <T, E>(
  query: string,
  variables: E
): Promise<T> => {
  const grapqlEnpoint = process.env.REACT_APP_GQL_ENDPOINT;
  const token = getLocalStorageElement(LOCAL_STORAGE_KEYS.TOKEN);
  const accessToken = getLocalStorageElement(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const response = await fetch(grapqlEnpoint || "", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken || "UNKNOWN"}`,
      token: token || UNKNOWN
    },
    body: JSON.stringify({ query, variables })
  });

  const result: GraphQLResponse<T> = await response.json();

  if (result.errors) {
    throw new Error(result.errors.map((error) => error.message).join("\n"));
  }

  return result.data;
};

export const uploadFileGraphQL = async <T, E>(
  query: string,
  variables: E,
  file: File
): Promise<T> => {
  const formData = new FormData();
  const grapqlEnpoint = process.env.REACT_APP_GQL_ENDPOINT;
  const token = getLocalStorageElement(LOCAL_STORAGE_KEYS.TOKEN);
  const accessToken = getLocalStorageElement(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);

  const map = JSON.stringify({
    file: ["variables.file"]
  });

  // Append fields to FormData
  formData.append("operations", JSON.stringify({ query, variables }));
  formData.append("map", map);
  formData.append("file", file);

  const response = await fetch(grapqlEnpoint || "", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken || "UNKNOWN"}`,
      token: token || UNKNOWN
    },
    body: formData
  });

  if (!response.ok) {
    throw new Error("Failed to upload file");
  }

  const result: GraphQLResponse<T> = await response.json();

  if (result.errors) {
    throw new Error(result.errors.map((error) => error.message).join("\n"));
  }

  return result.data;
};
