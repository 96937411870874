export const getAllEmployeesQuery = `
query GetAllPublicEmployees {
    getAllPublicEmployees {
        firstName
        lastName
        linkedin
        avatar
        positions
        email
    }
}
`;
