import React, { FC } from "react";
import { Container } from "@mui/material";
import { NavigationConfig } from "./types/navigationConfigType";
import NavigationMenu from "./TopNavigationMenu";

interface TopNavigationProps {
  navConfigList: NavigationConfig[];
}
const TopNavigation: FC<TopNavigationProps> = (props) => {
  const { navConfigList } = props;
  return (
    <Container id="topNavigationContainer">
      {navConfigList.map((navConfig) => (
        <NavigationMenu navConfig={navConfig} />
      ))}
    </Container>
  );
};

export default TopNavigation;
