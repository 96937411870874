import React, { FC, useContext } from "react";
import { Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Sidenavigation from "./Sidenavigation";
import TopNavigation from "./TopNavigation";
import {
  LoggedInNavItemConfig,
  NavigationConfigList,
  NotLoggedInNavItemConfig
} from "./config/navigationConfig";
import AuthContext from "../../Contexts/authContext";
import { AUTH_STATUS } from "../../Graphql/types/auth";

const Navigation: FC = () => {
  const theme = useTheme();
  const matchMD = useMediaQuery(theme.breakpoints.down("md"));
  const { authContextInfo } = useContext(AuthContext);
  // set navBar config based on login details
  const navConfig = [
    ...NavigationConfigList,
    ...(authContextInfo.auth.status === AUTH_STATUS.AUTHENTICATED
      ? LoggedInNavItemConfig
      : NotLoggedInNavItemConfig)
  ];

  return (
    <Container id="navigationContainer">
      {matchMD ? (
        <Sidenavigation navConfigList={navConfig} />
      ) : (
        <TopNavigation navConfigList={navConfig} />
      )}
    </Container>
  );
};

export default Navigation;
