import React, { FC } from "react";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { CarouselItemConfigType } from "./types/carouselItem";
import "./styles/carouselItem.scss";
import { OUR_SERVICES } from "../pageRoutes";

const HomeCarouselItem: FC<CarouselItemConfigType> = (props) => {
  const { headingDetail, headingTitle, exploreButtonLabel, bgVideoUrl } = props;

  const navigate = useNavigate();
  const theme = useTheme();
  const matchMD = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack
      style={{
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        fontWeight: "bold",
        textAlign: "center",
        width: "100vw",
        height: "auto",
        margin: 0,
        padding: "0 3em",
        overflow: "hidden",
        backdropFilter: "brightness(1)",
        backgroundImage:
          "https://kivunova.s3.us-east-1.amazonaws.com/home-bg.jpg"
      }}
    >
      <video
        autoPlay
        loop
        muted
        playsInline
        className="video-element"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          minWidth: "100%",
          minHeight: "100%",
          width: "auto",
          height: "auto",
          transform: "translate(-50%, -50%)",
          objectFit: "cover",
          zIndex: -1, // Ensures the video stays behind the content
          backdropFilter: "brightness(0.5)"
        }}
      >
        <source src={bgVideoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {!matchMD && <h2>{headingTitle}</h2>}
      <p
        style={{
          fontFamily: "monospace",
          fontWeight: "100",
          fontSize: "small"
        }}
      >
        {headingDetail}
      </p>
      <Stack
        sx={{
          padding: "2em 0px"
        }}
      >
        <button
          onClick={() => navigate(OUR_SERVICES)}
          className="openModalButton"
        >
          {exploreButtonLabel || "Learn more.."}
        </button>
      </Stack>
    </Stack>
  );
};

export default HomeCarouselItem;
