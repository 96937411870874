import React, { FC } from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import "./styles/footer.scss";

interface FooterLinkItemProps {
  title: string;
  linkUrl: string;
  isEmail?: boolean;
  isPhone?: boolean;
}

const FooterLinkItem: FC<FooterLinkItemProps> = (props) => {
  const { title, linkUrl, isEmail, isPhone } = props;
  const navigate = useNavigate();

  const handleClick = () => {
    if (isEmail) {
      window.location.href = `mailto:${linkUrl}`;
    } else if (isPhone) {
      window.location.href = `tel:${linkUrl}`;
    } else {
      navigate(linkUrl);
    }
  };

  return (
    <Typography variant="body2" className="footerSectionContentItem">
      <div
        tabIndex={0}
        role="link"
        onClick={handleClick}
        onKeyDown={(e) => e.key === "Enter" && handleClick()}
      >
        {title}
      </div>
    </Typography>
  );
};

export default FooterLinkItem;
