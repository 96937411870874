import React, { useContext, useEffect } from "react";
import { CircularProgress, Container } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchGraphQL } from "../../Graphql/utils";
import { logoutQuery } from "../../Graphql/querries/authQuery";
import { AUTH_STATUS, LogoutResponse } from "../../Graphql/types/auth";
import AuthContext from "../../Contexts/authContext";
import { LOGIN, REGISTRATION, VERIFY_OTP } from "../pageRoutes";
import { LOCAL_STORAGE_KEYS } from "../../Enums/localStorage";

const Logout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authContextInfo, updateAuthContextInfo } = useContext(AuthContext);

  const previousPage = [LOGIN, VERIFY_OTP, REGISTRATION].includes(
    location.state?.from
  )
    ? location.state?.from || "/"
    : "/";

  const { mutate } = useMutation<LogoutResponse, Error>({
    mutationKey: ["logout"],
    mutationFn: () => fetchGraphQL(logoutQuery, { input: null }),
    onSuccess: () => {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);

      if (updateAuthContextInfo) {
        updateAuthContextInfo({
          ...authContextInfo,
          auth: {
            status: AUTH_STATUS.UNAUTHENTICATED,
            memberId: undefined
          },
          otp: undefined
        });
      }

      navigate(previousPage);
    }
  });

  useEffect(() => {
    mutate();
  }, []);

  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        height: "85vh",
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
      }}
    >
      Hang tight, we&lsquo;re tidying up your session and logging you out!
      &nbsp; <CircularProgress size={20} />
    </Container>
  );
};

export default Logout;
