import React from "react";
import { Button, Container, Divider, Stack, Typography } from "@mui/material";
import { Parallax } from "react-parallax";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import WebhookIcon from "@mui/icons-material/Webhook";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { OurTeam } from "../OurTeam/OurTeam";
import "./styles/homepage.scss";
import ServiceChatBookingForm from "../OurServices/ServiceChatBookingForm";
import { CarouselItemConfig } from "./const/carouselItem";
import HomeCarousel from "./HomeCarousel";

const Homepage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matchMD = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <section id="homepageContainer">
      <HomeCarousel
        carouseItemConfig={CarouselItemConfig}
        height={matchMD ? "500px" : "700px"}
      />
      <Parallax
        blur={{ min: -10, max: -1 }}
        strength={100}
        className="parallax-content secondary"
        style={{ padding: 0 }}
      >
        <Stack
          justifyContent="center"
          spacing="1.3em"
          alignItems="center"
          flexDirection="column"
          padding="2em 0px 0px 0px"
        >
          <Typography variant="h4">Our Services</Typography>
          <Divider textAlign="left">
            <WebhookIcon color="primary" />
          </Divider>
        </Stack>
        <Stack
          style={{
            padding: "3em 0px",
            width: "100%"
          }}
          direction={matchMD ? "column" : "row"}
          justifyContent="center"
          alignItems="center"
        >
          <Stack
            padding="0px 5%"
            justifyContent="flex-start"
            alignItems="center"
            flexDirection="column"
            style={{
              width: matchMD ? "85%" : "50%",
              fontWeight: "light",
              height: "80%",
              display: "flex"
            }}
          >
            <p
              style={{
                fontSize: "large",
                lineHeight: 1.5
              }}
            >
              At Kivunova, we empower youth by providing tech opportunities,
              mentorship, and real-world projects, paving the way for
              professional success. Our mission extends beyond education, as we
              strive to simplify lives through innovative technology solutions,
              fostering a brighter, more connected future for everyone. Join us
              in transforming potential into achievement and dreams into
              reality.
            </p>
            <section>
              <Button
                color="primary"
                variant="text"
                onClick={() => {
                  navigate("our-services");
                }}
              >
                Read more...
              </Button>
            </section>
          </Stack>
          <Stack
            spacing="2em"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{
              width: matchMD ? "85%" : "50%",
              color: "#232f3e",
              fontWeight: "bold",
              height: "250px",
              overflow: "hidden",
              backdropFilter: "brightness(1)",
              position: "relative"
            }}
          >
            <video
              autoPlay
              loop
              muted
              playsInline
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                minWidth: "100%",
                minHeight: "100%",
                width: "100%",
                height: "100%",
                transform: "translate(-50%, -50%)",
                objectFit: "contain",
                zIndex: -1 // Ensures the video stays behind the content
              }}
            >
              <source
                src="https://kivunova.s3.us-east-1.amazonaws.com/kivunova-our-services.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </Stack>
        </Stack>
      </Parallax>
      <Parallax
        blur={{ min: -10, max: -1 }}
        strength={100}
        className="parallax-content secondary"
        style={{ padding: 0 }}
      >
        <Stack
          justifyContent="center"
          spacing="1.3em"
          alignItems="center"
          flexDirection="column"
          padding="2em 0px 0px 0px"
        >
          <Typography variant="h4">Technologies</Typography>
          <Divider textAlign="left">
            <SettingsSuggestIcon color="primary" />
          </Divider>
        </Stack>
        <Stack
          style={{
            padding: "3em 0px",
            width: "100%"
          }}
          direction={matchMD ? "column" : "row"}
          justifyContent="center"
          alignItems="center"
        >
          <Stack
            spacing="2em"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{
              width: matchMD ? "85%" : "50%",
              color: "#232f3e",
              fontWeight: "bold",
              height: "250px",
              overflow: "hidden",
              backdropFilter: "brightness(1)",
              position: "relative"
            }}
          >
            <video
              autoPlay
              loop
              muted
              playsInline
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                minWidth: "100%",
                minHeight: "100%",
                width: "100%",
                height: "100%",
                transform: "translate(-50%, -50%)",
                objectFit: "contain",
                zIndex: -1 // Ensures the video stays behind the content
              }}
            >
              <source
                src="https://kivunova.s3.us-east-1.amazonaws.com/kivunova-technologies.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </Stack>
          <Stack
            padding="0px 5%"
            justifyContent="flex-start"
            alignItems="center"
            flexDirection="column"
            style={{
              width: matchMD ? "85%" : "50%",
              fontWeight: "light",
              height: "80%",
              display: "flex"
            }}
          >
            <p
              style={{
                fontSize: "large",
                lineHeight: 1.5
              }}
            >
              At Kivunova, we pride ourselves on leveraging a diverse array of
              cutting-edge technologies to deliver robust and scalable solutions
              for our clients. We specialize in frontend, backend technologies,
              Machine learning and AI to give you a comprehensive view of our
              technical expertise.
            </p>
            <section>
              <Button
                color="primary"
                variant="text"
                onClick={() => {
                  navigate("technologies");
                }}
              >
                Read more...
              </Button>
            </section>
          </Stack>
        </Stack>
      </Parallax>
      <Stack
        minHeight="150px"
        width="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        display="flex"
        spacing="2em"
        padding="4em 0"
      >
        <Container
          sx={{
            width: "90%",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <Typography
            variant={matchMD ? "subtitle1" : "h6"}
            textAlign="center"
            fontWeight="bold"
          >
            Subscribe to chat with tech team today!
          </Typography>
          <Stack>
            <ServiceChatBookingForm />
          </Stack>
        </Container>
      </Stack>
      <OurTeam />
    </section>
  );
};

export default Homepage;
