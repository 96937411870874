import { CarouselItemConfigType } from "../types/carouselItem";

export const CarouselItemConfig: CarouselItemConfigType[] = [
  {
    headingTitle: "Elevating Potentials, Igniting Futures!",
    headingDetail:
      "We offer mentorship and resources, shaping innovation and fostering opportunities in the dynamic field of technology.",
    exploreButtonLabel: "Learn more..",
    modalTitle: "Learn more about our mentorship program",
    ytVideoLink: "dQw4w9WgXcQ",
    bgVideoUrl:
      "https://kivunova.s3.us-east-1.amazonaws.com/Kivunova-mentoring.mp4"
  },
  {
    headingTitle: "Partner with us in every stage of your journey!",
    headingDetail:
      "Build your dedicated remote team, with full control and get your deliverable save up to 70% on employees costs.",
    exploreButtonLabel: "Learn more..",
    modalTitle: "Learnmore about our services",
    ytVideoLink: "dQw4w9WgXcQ",
    bgVideoUrl:
      "https://kivunova.s3.us-east-1.amazonaws.com/kivunova-office.mp4"
  }
];
