import React, { FC, useContext } from "react";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Parallax } from "react-parallax";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { useQuery } from "@tanstack/react-query";
import { LinearProgress } from "@mui/material";
import HorizontalScroll from "../Common/HorizontalScroll/HorizontalScroll";
import TeamMemberCard from "./TeamMemberCard";
import { fetchGraphQL } from "../../Graphql/utils";
import { getAllEmployeesQuery } from "../../Graphql/querries/employeeQuery";
import { GetEmployeesResponse } from "../../Graphql/types/employee";
import AlertBannerContext from "../../Contexts/alertBannerContext";

interface OurTeamProps {
  hideTitle?: boolean;
}

export const OurTeam: FC<OurTeamProps> = (props) => {
  const { hideTitle } = props;
  const { setAlert } = useContext(AlertBannerContext);
  const { data, error, isFetching } = useQuery<GetEmployeesResponse>({
    queryKey: ["getEmployees"],
    queryFn: () => fetchGraphQL(getAllEmployeesQuery, {}, setAlert)
  });

  if (isFetching) {
    return <LinearProgress />;
  }

  if (error) {
    return <div />;
  }

  return (
    <Parallax
      blur={{ min: -10, max: -1 }}
      strength={100}
      className="parallax-content secondary"
      style={{ padding: 0 }}
    >
      <Stack
        justifyContent="center"
        spacing="1.3em"
        alignItems="center"
        flexDirection="column"
        padding="2em 0px 0px 0px"
      >
        {!hideTitle && (
          <>
            <Typography variant="h4">Our Team</Typography>
            <Divider textAlign="left">
              <SettingsSuggestIcon color="primary" />
            </Divider>
          </>
        )}
      </Stack>
      <HorizontalScroll>
        {data?.getAllPublicEmployees?.map((t) => (
          <TeamMemberCard
            name={`${t.firstName} ${t.lastName.charAt(0).toUpperCase()}.`}
            email={t.email}
            role={t.hiredEmployeeInfo?.positions?.join(" & ")}
            photoUrl={t.hiredEmployeeInfo?.avatar}
            linkedInUrl={t.additionalEmployeeInfo?.linkedInUrl}
          />
        ))}
      </HorizontalScroll>
      <Stack
        padding="0px 5%"
        justifyContent="flex-start"
        alignItems="center"
        flexDirection="column"
        style={{
          width: "50%",
          fontWeight: "light",
          height: "80%",
          display: "flex"
        }}
      >
        <section>
          {/* <Button color='primary' variant="text" onClick={() => { navigate('technologies') }}>Read more...</Button> */}
        </section>
      </Stack>
    </Parallax>
  );
};
