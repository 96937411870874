export const createApplicationQuery = `
mutation CreateApplication($input: CreateApplicationInput!, $file: Upload!) {
    createApplication(input: $input, file: $file) {
      application {
        id
        jobId
        memberId
        previousStatus
        status
      }
    }
}
`;

export const getApplicationListByMemberIdQuery = `
query GetApplicationListByMemberId($memberId: String!) {
    getApplicationListByMemberId(memberId: $memberId) {
        id
        jobId
        memberId
        status
        submittedDate
        previousStatus
        jobInfo {
          jobTitle
          jobLocation
          totalPay
          jobRole
          jobLevel
          jobType
          duration
          currencyCode
        }
    }
}
`;

export const getApplicationListWithFilterQuery = `
query GetApplicationListWithFilters($input: getApplicationListWithFiltersInput!) {
    getApplicationListWithFilters(input: $input) {
        id
        jobId
        memberId
        status
        submittedDate
        previousStatus
        jobInfo {
          jobTitle
          jobLocation
          totalPay
          jobRole
          jobLevel
          jobType
          duration
          currencyCode
        }
    }
}
`;

export const withdrawApplicationQuery = `
mutation WithdrawApplication($input: WithdrawApplicationInput!) {
    withdrawApplication(input: $input) {
        status
        application {
            id
            jobId
            memberId
            status
            submittedDate
            previousStatus
            jobInfo {
                jobTitle
                jobLocation
                totalPay
                jobRole
                jobLevel
                jobType
                duration
                currencyCode
            }
        }
    }
}
`;
