import React, { FC, useState } from "react";
import { Stack } from "@mui/material";

import YouTubeVideo from "../Common/YoutubeVideo/YoutubeVideo";
import Modal from "../Common/Modal/Modal";
import { CarouselItemConfigType } from "./types/carouselItem";
import "./styles/carouselItem.scss";

const HomeCarouselItem: FC<CarouselItemConfigType> = (props) => {
  const {
    headingDetail,
    headingTitle,
    ytVideoLink,
    exploreButtonLabel,
    modalTitle,
    bgImage
  } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Stack
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        grid: "10",
        flexDirection: "column",
        color: "white",
        fontWeight: "bold",
        textAlign: "center",
        width: "100vw",
        backgroundImage: `url(${bgImage})`,
        margin: 0,
        height: "100%",
        backdropFilter: "brightness(0.6)",
        padding: "0 3em"
      }}
    >
      <h2>{headingTitle}</h2>
      <p
        style={{
          fontFamily: "monospace",
          fontWeight: "100",
          fontSize: "small"
        }}
      >
        {headingDetail}
      </p>
      <Stack
        sx={{
          padding: "2em 0px"
        }}
      >
        <button onClick={handleOpen} className="openModalButton">
          {exploreButtonLabel || "Learn more.."}
        </button>
        {open && (
          <Modal
            title={modalTitle || "Welcome to Kivu Nova"}
            closeButton={handleClose}
          >
            <YouTubeVideo videoId={ytVideoLink} />
          </Modal>
        )}
      </Stack>
    </Stack>
  );
};

export default HomeCarouselItem;
