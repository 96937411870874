import React from "react";
import "./styles/teamMemberCard.scss";

interface TeamMemberCardProps {
  name: string;
  role: string;
  photoUrl: string;
  linkedInUrl: string;
  email: string;
}

const TeamMemberCard: React.FC<TeamMemberCardProps> = ({
  name,
  role,
  photoUrl,
  linkedInUrl,
  email
}) => {
  return (
    <div className="team-card">
      <img
        src={photoUrl}
        alt={`${name}'s profile`}
        className="team-card-photo"
        width={150}
        height={150}
      />
      <div className="team-card-info">
        <h3 className="team-card-name">{name}</h3>
        <p className="team-card-role">{role}</p>
        <div className="team-card-icons">
          <a href={linkedInUrl} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin" />
          </a>
          <a href={`mailto:${email}`}>
            <i className="fas fa-envelope" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default TeamMemberCard;
