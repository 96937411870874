import { Container, List, ListItemButton, ListItemText } from "@mui/material";
import React, { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { NavigationConfig } from "./types/navigationConfigType";
import PageThemeContext from "../../Contexts/pageThemeContext";

// eslint-disable-next-line @typescript-eslint/no-require-imports
const classNames = require("classnames");

interface NavigationMenuItemProps {
  navigations: NavigationConfig[];
  close: () => void;
}

const NavigationMenuItem: FC<NavigationMenuItemProps> = (props) => {
  const { navigations, close } = props;
  const navigate = useNavigate();
  const theme = useContext(PageThemeContext);
  const topNavigationMenuClass = classNames(
    "navigationMenuItem",
    `page-theme-${theme}`
  );

  return (
    <Container className={topNavigationMenuClass}>
      <div className="poppover-tip" />
      <List>
        {navigations.map((nav) => (
          <ListItemButton
            onClick={() => {
              if (nav.isExternal) {
                window.location.assign(nav.path || "");
              } else {
                navigate(nav.path || "");
              }
              close();
            }}
            className="navigationMenuItemContent"
          >
            <ListItemText primary={nav.title} />
          </ListItemButton>
        ))}
      </List>
    </Container>
  );
};

export default NavigationMenuItem;
