import React, { FC, useContext, useEffect } from "react";
import { Tabs, Tab, Box, LinearProgress, Stack } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import ApplicationCard from "./ApplicationCard";
import PageContent from "../Common/PageContent/PageContent";
import PageHeading from "../Common/PageHeading/PageHeading";
import WithAuth from "../../Hoc/WithAuth";
import { fetchGraphQL } from "../../Graphql/utils";
import { getApplicationListWithFilterQuery } from "../../Graphql/querries/applicationQuery";
import AlertBannerContext from "../../Contexts/alertBannerContext";
import AuthContext from "../../Contexts/authContext";
import {
  ApplicationStatus,
  getApplicationListWithFiltersInput,
  getApplicationListWithFiltersResponse
} from "../../Graphql/types/application";
import { AUTH_STATUS } from "../../Graphql/types/auth";
import NoApplicationFound from "./NoApplicationFound";

const ApplicationList: FC = () => {
  const [selectedTab, setSelectedTab] = React.useState<
    ApplicationStatus | number
  >(0);
  const { setAlert } = useContext(AlertBannerContext);
  const { authContextInfo } = useContext(AuthContext);

  const handleTabChange = (
    _event: React.SyntheticEvent,
    newValue: ApplicationStatus | number
  ) => {
    setSelectedTab(newValue);
  };

  const { mutate, data, isPending } = useMutation<
    getApplicationListWithFiltersResponse,
    Error,
    getApplicationListWithFiltersInput
  >({
    mutationKey: ["getApplicationListWithFilter"],
    mutationFn: (input) =>
      fetchGraphQL(getApplicationListWithFilterQuery, { input }, setAlert)
  });

  useEffect(() => {
    if (
      authContextInfo.auth.status === AUTH_STATUS.AUTHENTICATED &&
      authContextInfo.auth.memberId
    ) {
      mutate({
        memberId: authContextInfo.auth.memberId,
        status: selectedTab ? (selectedTab as ApplicationStatus) : undefined
      });
    }
  }, [authContextInfo.auth.status, authContextInfo.auth.memberId, selectedTab]);

  return (
    <section>
      <PageHeading titleText="Manage your applications" />
      <PageContent styles={{ background: "white" }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          style={{ padding: "1.5em 0 1em 0" }}
          centered
        >
          <Tab label="All Applications" value={0} />
          <Tab label="Submitted" value={ApplicationStatus.SUBMITTED} />
          <Tab label="Viewed" value={ApplicationStatus.VIEWED} />
          <Tab label="Interview" value={ApplicationStatus.INTERVIEW} />
          <Tab label="Offer" value={ApplicationStatus.OFFER} />
          <Tab
            label="Not Considered"
            value={ApplicationStatus.NOT_CONSIDERED}
          />
          <Tab label="Not Selected" value={ApplicationStatus.NOT_SELECTED} />
          <Tab label="Withdrawn" value={ApplicationStatus.WITHDRAWN} />
          <Tab label="Hired" value={ApplicationStatus.HIRED} />
        </Tabs>
        <Box mt={3} display="flex" flexDirection="column" gap={3}>
          {isPending && (
            <Stack
              width="100%"
              paddingBottom="1em"
              display="flex"
              justifyContent="center"
            >
              <LinearProgress />
            </Stack>
          )}
          {data?.getApplicationListWithFilters?.length
            ? data?.getApplicationListWithFilters.map((app) => (
                <ApplicationCard key={app.id} application={app} />
              ))
            : !isPending && <NoApplicationFound />}
        </Box>
      </PageContent>
    </section>
  );
};

export default WithAuth(ApplicationList);
