import React from "react";
import CalculateIcon from "@mui/icons-material/Calculate";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import SettingsIcon from "@mui/icons-material/Settings";
import { ServiceItem } from "../types";

export const ServiceList: ServiceItem[] = [
  {
    title: "Health Care",
    icon: <LocalHospitalIcon fontSize="large" />,
    serviceList: [
      "Customer Service Manager",
      "Senior Customer Service Operator",
      "Customer Support Specialist",
      "Customer Service Team Leader",
      "Sales Specialist",
      "Client Onboarding Specialist"
    ]
  },
  {
    title: "Finance & Accounting",
    icon: <CalculateIcon fontSize="large" />,
    serviceList: [
      "Financial Accountant",
      "Accounts Receivable / Payable Officer",
      "Collections Officer",
      "Bookkeeper & Payroll Specialist",
      "Tax Accountant",
      "Investment Accountant"
    ]
  },
  {
    title: "Admin & Office Processing",
    icon: <SettingsIcon fontSize="large" />,
    serviceList: [
      "Executive Assistant",
      "Administrative Manager",
      "Recruitment Consultant",
      "Data Entry Specialist",
      "Data Administration Team Leader"
    ]
  }
];
