import React from "react";
import { Event } from "./types";

import "./styles/eventCard.scss";

interface EventCardProps {
  event: Event;
  onClick: () => void;
}

const EventCard: React.FC<EventCardProps> = ({ event, onClick }) => (
  <div
    tabIndex={0}
    role="link"
    className="event-card"
    onClick={onClick}
    onKeyDown={(e) => e.key === "Enter" && onClick()}
  >
    <h3>{event.title}</h3>
    <p>{event.date.toDateString()}</p>
  </div>
);

export default EventCard;
