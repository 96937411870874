import React, { useState } from "react";
import YouTube from "react-youtube";
import "./YoutubeVideo.scss";

type YouTubeVideoProps = {
  videoId: string;
};

const YoutubeVideo: React.FC<YouTubeVideoProps> = ({ videoId }) => {
  const [loading, setLoading] = useState(true);

  const handleOnReady = () => {
    setLoading(false);
  };

  return (
    <div className="youtube-container">
      {loading && <div className="loader">Loading...</div>}
      <div className={`video-wrapper ${loading ? "Loading.." : "loaded"}`}>
        <YouTube
          className="video-player"
          videoId={videoId}
          onReady={handleOnReady}
        />
      </div>
    </div>
  );
};

export default YoutubeVideo;
