import React, { useState, useEffect, ReactNode } from "react";
import { Box, IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

interface CustomCarouselProps {
  autoPlay?: boolean; // Whether to autoplay slides
  autoPlayDelay?: number; // Delay in milliseconds for autoplay
  height?: number | string; // Carousel height
  width?: number | string; // Carousel width
  showControllers?: boolean; // Whether to show navigation arrows and dots
  showControllersOnHover?: boolean; // Show controllers only on hover
  children: ReactNode[];
  slideLength: number;
}

const CustomCarousel: React.FC<CustomCarouselProps> = ({
  autoPlay,
  autoPlayDelay,
  height,
  width,
  showControllers,
  showControllersOnHover,
  slideLength,
  children
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false); // Track if autoplay is paused
  const [isHovered, setIsHovered] = useState(false); // Track hover state

  // Function to move to the next slide
  const nextSlide = () => {
    setCurrentIndex((prev) => (prev + 1) % slideLength);
  };

  // Function to move to the previous slide
  const prevSlide = () => {
    setCurrentIndex((prev) => (prev - 1 + slideLength) % slideLength);
  };

  // Autoplay effect
  useEffect(() => {
    if (autoPlay && !isPaused) {
      const interval = setInterval(nextSlide, autoPlayDelay);
      return () => clearInterval(interval); // Cleanup on unmount
    }
    return () => {};
  }, [autoPlay, autoPlayDelay, isPaused]);

  // This is a hackway to avoid eslint issue
  const indicators = Array.from({ length: slideLength }, (_, index) => ({
    id: `indicator-${index}`
  }));

  return (
    <Box
      sx={{
        position: "relative",
        width,
        height,
        overflow: "hidden"
      }}
      onMouseEnter={() => {
        setIsHovered(true);
        setIsPaused(true);
      }} // Set hover state
      onMouseLeave={() => {
        setIsHovered(false);
        setIsPaused(false);
      }} // Reset hover state
    >
      {/* Slides Container */}
      {children.map((child, index) => (
        <Box
          sx={{
            position: "absolute", // Remove the slide from the layout flow
            top: 0,
            left: 0,
            display: "flex",
            opacity: currentIndex === index ? 1 : 0,
            visibility: currentIndex === index ? "visible" : "hidden", // Control visibility
            transition: "opacity 1.5s ease, visibility 1.5s ease",
            width: "100%",
            flexDirection: "row",
            justifyContent: "start",
            height: "100%"
          }}
        >
          {child}
        </Box>
      ))}

      {/* Controllers */}
      {showControllers && (
        <Box
          sx={{
            opacity: showControllersOnHover && !isHovered ? 0 : 1,
            transition: "opacity 0.3s"
          }}
        >
          {/* Left Navigation Button */}
          <IconButton
            onClick={prevSlide}
            sx={{
              position: "absolute",
              top: "50%",
              left: "10px",
              transform: "translateY(-50%)",
              zIndex: 2,
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.8)"
              }
            }}
          >
            <ChevronLeft />
          </IconButton>

          {/* Right Navigation Button */}
          <IconButton
            onClick={nextSlide}
            sx={{
              position: "absolute",
              top: "50%",
              right: "10px",
              transform: "translateY(-50%)",
              zIndex: 2,
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.8)"
              }
            }}
          >
            <ChevronRight />
          </IconButton>

          {/* Dots (Indicators) */}
          <Box
            sx={{
              position: "absolute",
              bottom: "10px",
              left: "50%",
              transform: "translateX(-50%)",
              display: "flex",
              gap: "8px"
            }}
          >
            {indicators.map((item, index) => (
              <Box
                key={item.id}
                onClick={() => setCurrentIndex(index)}
                sx={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  backgroundColor:
                    currentIndex === index
                      ? "white"
                      : "rgba(255, 255, 255, 0.5)",
                  cursor: "pointer",
                  transition: "background-color 0.3s"
                }}
              />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

// Default props
CustomCarousel.defaultProps = {
  autoPlay: true,
  autoPlayDelay: 3000,
  height: 400,
  width: "100%",
  showControllers: true,
  showControllersOnHover: false
};

export default CustomCarousel;
