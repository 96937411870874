export const getAllTechnologiesQuery = `
query GetAllTechnologies {
    getAllTechnologies {
        id
        title
        description
        technologyItems {
            title
            description
            detailUrl
        }
    }
}
`;
