import React, { FC } from "react";
import PageHeading from "../Common/PageHeading/PageHeading";
import PageContent from "../Common/PageContent/PageContent";
import { OurTeam } from "./OurTeam";

export const MeetOurTeam: FC = () => {
  return (
    <section
      style={{
        background: "rgb(226, 224, 224)"
      }}
    >
      <PageHeading titleText="Meet everyone that's making it all happen" />
      <PageContent>
        <OurTeam hideTitle />
      </PageContent>
    </section>
  );
};

export default MeetOurTeam;
