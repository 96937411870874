import React, { ComponentType, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import urlcat from "urlcat";
import AuthContext from "../Contexts/authContext";
import { AUTH_STATUS, AuthorizeResponse } from "../Graphql/types/auth";
import { LOGIN } from "../Components/pageRoutes";
import { LOCAL_STORAGE_KEYS } from "../Enums/localStorage";
import { authorizeQuery } from "../Graphql/querries/authQuery";
import { fetchGraphQL } from "../Graphql/utils";
import { setLocalStorageElement } from "../Utils/localStorageUtils";
import AlertBannerContext from "../Contexts/alertBannerContext";

// Higher-Order Component
const WithAuth = <P extends object>(
  WrappedComponent: ComponentType<P>
): React.FC<P> => {
  const AuthenticatedComponent: React.FC<P> = (props) => {
    const navigate = useNavigate();
    const loginUrl = urlcat(LOGIN, {
      redirectPage: window.location.pathname
    });

    const { authContextInfo, updateAuthContextInfo } = useContext(AuthContext);
    const { setAlert } = useContext(AlertBannerContext);

    const { mutate } = useMutation<AuthorizeResponse, Error>({
      mutationKey: ["authorize"],
      mutationFn: () => fetchGraphQL(authorizeQuery, { input: null }, setAlert),
      onSuccess: (response) => {
        const { status, accessToken, userId: memberId } = response.authorize;
        if (status !== AUTH_STATUS.AUTHENTICATED) {
          localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
          localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);

          navigate(loginUrl);
        } else if (accessToken)
          setLocalStorageElement(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, accessToken);
        updateAuthContextInfo({
          ...authContextInfo,
          auth: { status, memberId }
        });
      },
      onError: () => {
        navigate(loginUrl);
      }
    });

    // Always redirect to previous page if user already logged in
    useEffect(() => {
      if (authContextInfo.auth.status !== AUTH_STATUS.AUTHENTICATED) {
        mutate();
      }
    }, [authContextInfo]);

    // Render the wrapped component if authenticated
    return <WrappedComponent {...props} />;
  };

  return AuthenticatedComponent;
};

export default WithAuth;
