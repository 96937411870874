import React, { FC, useState } from "react";
import { Container, Stack } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Flyover from "../Common/Flyover/Flyover";
import SidenavigationMenu from "./SideNavigationMenu";

const Sidenavigation: FC = () => {
  const [showFlyOver, setShowFlyOver] = useState<boolean>(false);
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end"
      }}
    >
      <Stack
        onClick={() => {
          setShowFlyOver(!showFlyOver);
        }}
      >
        <MenuIcon />
      </Stack>
      <Flyover
        isOpen={showFlyOver}
        closeMenu={() => {
          setShowFlyOver(!showFlyOver);
        }}
      >
        <Container>
          <SidenavigationMenu
            closeMenu={() => {
              setShowFlyOver(!showFlyOver);
            }}
          />
        </Container>
      </Flyover>
    </Container>
  );
};

export default Sidenavigation;
