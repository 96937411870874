export enum AUTH_STATUS {
  UNAUTHENTICATED = "UNAUTHENTICATED",
  AUTHENTICATED = "AUTHENTICATED",
  TOKEN_EXPIRED = "TOKEN_EXPIRED"
}

export enum LOGOUT_STATUS {
  LOGGED_OUT = "LOGGED_OUT",
  TOKEN_ALREADY_EXPIRED = "TOKEN_ALREADY_EXPIRED"
}

export enum OTP_STATUS {
  VERIFIED = "VERIFIED",
  INVALID = "INVALID",
  ALREADY_EXIST = "ALREADY_EXIST",
  WAIT_TIMEOUT = "WAIT_TIMEOUT",
  SENT = "SENT",
  RESENT = "RESENT"
}

export interface SendOtpVariable {
  email: string;
}

export interface SendOtpResponse {
  sendOtp: {
    status: OTP_STATUS;
  };
}

export interface ValidateOtpVariable {
  email: string;
  otp: string;
}

export interface ValidateOtpResponse {
  validateOtp: {
    status: OTP_STATUS;
    accessToken: string;
    token: string;
  };
}

export interface AuthorizeResponse {
  authorize: {
    status: AUTH_STATUS;
    memberId: string;
    accessToken?: string;
  };
}

export interface LogoutResponse {
  logout: {
    status: LOGOUT_STATUS;
  };
}

export interface Member {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface AddMemberResponse {
  addMember: Member;
}
