import React, { useCallback } from "react";
import { Stack } from "@mui/material";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Model } from "survey-core";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Survey } from "survey-react-ui";
// eslint-disable-next-line import/no-extraneous-dependencies
import "survey-core/defaultV2.min.css";

const surveyJson = {
  elements: [
    {
      name: "FirstName",
      title: "Enter your first name:",
      type: "text"
    },
    {
      name: "LastName",
      title: "Enter your last name:",
      type: "text"
    }
  ]
};

const SurveyForm = () => {
  const surveyModel = new Model(surveyJson);
  const alertResults = useCallback((sender) => {
    const results = JSON.stringify(sender.data);
    /* eslint-disable no-console */
    console.log(results, sender);
  }, []);

  surveyModel.onComplete.add(alertResults);
  return (
    <Stack>
      <Survey model={surveyModel} />
    </Stack>
  );
};

export default SurveyForm;
