import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { APPLICATION_LIST, HOMEPAGE, JOBS } from "../pageRoutes";

const ApplySuccess = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "2em",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        padding: "1em",
        minHeight: "30vh",
        boxSizing: "border-box"
      }}
    >
      <CheckCircleIcon sx={{ fontSize: 60, color: "green" }} />
      <Typography variant="h6" textAlign="center">
        All set!!
      </Typography>
      <Typography variant="body1" padding="2em auto" textAlign="center">
        Thank you for applying. Your application has been submitted
        successfully, our team will reach out to you for the next step.
      </Typography>
      <Stack
        display="flex"
        width="100%"
        flexDirection={matchSM ? "column" : "row"}
        gap={3}
        justifyContent="center"
        alignItems="center"
        padding="1em"
      >
        <Button
          variant="text"
          onClick={() => {
            navigate(JOBS);
          }}
        >
          Search Jobs
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            navigate(HOMEPAGE);
          }}
        >
          Go to Homepage
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            navigate(APPLICATION_LIST);
          }}
        >
          Manage Applications
        </Button>
      </Stack>
    </Box>
  );
};

export default ApplySuccess;
