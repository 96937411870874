import React, { FC } from "react";
import { Grid, LinearProgress, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import PageHeading from "../Common/PageHeading/PageHeading";
import PageContent from "../Common/PageContent/PageContent";
import TechnologyCard from "./TechnologyCard";

import "./styles/index.scss";
import { fetchGraphQL } from "../../Graphql/utils";
import { getAllTechnologiesResponse } from "../../Graphql/types/technology";
import { getAllTechnologiesQuery } from "../../Graphql/querries/technologyQuery";

export const Technologies: FC = () => {
  const { data, isFetching } = useQuery<getAllTechnologiesResponse>({
    queryKey: ["getAllTechnologies"],
    queryFn: () => fetchGraphQL(getAllTechnologiesQuery, {})
  });

  return (
    <section>
      <PageHeading titleText="Our Technologies" />
      <PageContent styles={{ background: "white" }}>
        {/* <Typography variant="h4" gutterBottom style={{ margin: '20px 0px', textAlign: 'center' }}>
                    Our Technologies
                </Typography> */}
        <Typography
          variant="body1"
          gutterBottom
          style={{ margin: "3em 0px", textAlign: "center" }}
        >
          At Kivunova, we pride ourselves on leveraging a diverse array of
          cutting-edge technologies to deliver robust and scalable solutions for
          our clients. Our commitment to excellence and innovation is reflected
          in the tools and frameworks we utilize, ensuring that our projects are
          built to the highest standards. Below, we have outlined the
          technologies we specialize in, categorized into frontend and backend
          technologies, to give you a comprehensive view of our technical
          expertise.
        </Typography>
        {isFetching && (
          <Stack
            width="100%"
            paddingBottom="1em"
            display="flex"
            justifyContent="center"
          >
            <LinearProgress />
          </Stack>
        )}
        {data?.getAllTechnologies?.map((technology) => (
          <Stack key={technology.title}>
            <Typography
              aria-label="Frontend Technologies title"
              variant="h6"
              gutterBottom
              style={{ margin: "20px 0px", textAlign: "center" }}
            >
              {technology.title}
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ margin: "3em 0px", textAlign: "center" }}
              aria-label="Frontend Technologies details"
            >
              {technology.description}
            </Typography>
            <Grid container spacing={2}>
              {technology.technologyItems.map((tech) => (
                <Grid item xs={12} sm={6} md={4} key={tech.title}>
                  <TechnologyCard technology={tech} />
                </Grid>
              ))}
            </Grid>
          </Stack>
        ))}
      </PageContent>
    </section>
  );
};

export default Technologies;
