import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from "@mui/material";

// Define props type for the RoleCard component
interface ServiceCardProps {
  title: string;
  icon: React.ReactElement;
  serviceList: string[];
}

// RoleCard component to render each card
const ServiceCard: React.FC<ServiceCardProps> = ({
  title,
  icon,
  serviceList
}) => (
  <Card
    variant="outlined"
    sx={{ height: "100%" }}
    style={{ background: "linear-gradient(114deg, #fff, #a0cee975)" }}
  >
    <CardContent>
      <Grid container direction="column" alignItems="center">
        <Grid item>{icon}</Grid>
        <Grid item>
          <Typography
            variant="h6"
            component="div"
            align="center"
            fontWeight="regular"
          >
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <List>
            {serviceList.map((role) => (
              <ListItem key={role}>
                <ListItemIcon sx={{ fontSize: 20 }}>🔥</ListItemIcon>
                <ListItemText primary={role} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default ServiceCard;
