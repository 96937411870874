export enum EmploymentPosition {
  SDE = "SDE",
  HR = "HR",
  PM = "PM",
  ACCOUNTANT = "ACCOUNTANT",
  CUSTOMER_SERVICE = "CUSTOMER_SERVICE"
}

export enum EmploymentPositionLevel {
  STAFF = "STAFF",
  SENIOR = "SENIOR",
  JUNIOR = "JUNIOR",
  INTERN = "INTERN"
}
export enum EmploymentType {
  EMPLOYEE = "EMPLOYEE",
  STAFF = "STAFF",
  ADMIN = "ADMIN"
}

export enum EmploymentDuration {
  FULL_TIME = "FULL_TIME",
  PART_TIME = "PART_TIME"
}

export enum PermissionRole {
  DEFAULT = "DEFAULT",
  EMPLOYEE = "EMPLOYEE",
  STAFF = "STAFF",
  ADMIN = "ADMIN"
}

export interface Address {
  country: string;
  province: string;
  district: string;
  sector: string;
  cell: string;
  village: string;
}

export interface Employee {
  id: string;
  firstName: string;
  lastName: string;
  linkedin: string;
  avatar: string;
  positions: EmploymentPosition[];
  positionLevel: EmploymentPositionLevel;
  employmentType: EmploymentType;
  employmentDuration: EmploymentDuration;
  dateOfBirth: string;
  hireDate: string;
  rehireDate: string;
  resignDate: string;
  resignReason: string;
  email: string;
  phoneNumber: string;
  role: PermissionRole;
}

export interface GetEmployeesResponse {
  getAllPublicEmployees: Employee[];
}
