import React, { FC } from "react";
import { Container, Stack, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import FooterLinkItem from "./FooterLinkItem";
import "./styles/footer.scss";
import {
  ABOUT_US,
  CAREER_CONNECT,
  MEET_OUR_TEAM,
  OUR_SERVICES,
  TECHNOLOGIES
} from "../pageRoutes";

// eslint-disable-next-line @typescript-eslint/no-require-imports
const classNames = require("classnames");

interface FooterProps {
  hideFooterLinks?: boolean;
}

const Footer: FC<FooterProps> = (props) => {
  const { hideFooterLinks } = props;
  const theme = useTheme();
  const matchMD = useMediaQuery(theme.breakpoints.down("md"));

  const footerContainerClasses = classNames("footerContainer", {
    smallScreen: matchMD
  });

  return (
    <Stack className="footer">
      {!hideFooterLinks && (
        <Stack className={footerContainerClasses}>
          <Container className="footerSection">
            <Typography variant="h6" className="footerSectionTitle">
              About us
            </Typography>
            <Container className="footerSectionContent">
              <FooterLinkItem title="About us" linkUrl={ABOUT_US} />
              <FooterLinkItem title="Our services" linkUrl={OUR_SERVICES} />
              <FooterLinkItem title="Our team" linkUrl={MEET_OUR_TEAM} />
              {/* <FooterLinkItem title='Our clients' linkUrl={PARTNERS_AND_CLIENTS} /> */}
            </Container>
          </Container>
          <Container className="footerSection">
            <Typography variant="h6" className="footerSectionTitle">
              Resources
            </Typography>
            <Container className="footerSectionContent">
              <FooterLinkItem title="Technologies" linkUrl={TECHNOLOGIES} />
            </Container>
          </Container>
          <Container className="footerSection">
            <Typography variant="h6" className="footerSectionTitle">
              Events
            </Typography>
            <Container className="footerSectionContent">
              <FooterLinkItem title="Career Connect" linkUrl={CAREER_CONNECT} />
            </Container>
          </Container>
          <Container className="footerSection">
            <Typography variant="h6" className="footerSectionTitle">
              Contact us
            </Typography>
            <Container className="footerSectionContent">
              <FooterLinkItem title="+25078657483" linkUrl="" isPhone />
              <FooterLinkItem title="support@kivunova.com" linkUrl="" isEmail />
              <Stack />
            </Container>
          </Container>
        </Stack>
      )}
      <section className="footerCopyRight">2024 © Kivunova</section>
    </Stack>
  );
};

export default Footer;
