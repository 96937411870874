import React, { FC, useState } from "react";
import { Container, List, ListItemButton, ListItemText } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { NavigationConfig } from "./types/navigationConfigType";
import NavigationMenuItem from "./NavigationMenuItem";
import NavigationItem from "./NavigationItem";

interface TopNavigationMenuProps {
  navConfig: NavigationConfig;
}

const TopNavigationMenu: FC<TopNavigationMenuProps> = (props) => {
  const { navConfig } = props;
  const [open, setOpen] = useState(false);

  const resetNavMenu = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(!open);
    if (!open) {
      setTimeout(() => {
        window.addEventListener("click", resetNavMenu, { once: true });
      });
    }
  };

  return (
    <Container id="topNavigationMenuContainer">
      {navConfig.subLinks ? (
        <List sx={{ pl: 0 }}>
          <ListItemButton
            onClick={handleOpen}
            className={`topNavigationMenuContent ${open ? "selected" : ""}`}
          >
            <ListItemText primary={navConfig.title} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          {open && (
            <NavigationMenuItem
              navigations={navConfig.subLinks}
              close={resetNavMenu}
            />
          )}
        </List>
      ) : (
        <NavigationItem navConfig={navConfig} />
      )}
    </Container>
  );
};

export default TopNavigationMenu;
