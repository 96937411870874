import React, { useContext, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Homepage from "./Components/Homepage";
import Header from "./Components/Header/Header";
import PageThemeContext from "./Contexts/pageThemeContext";
import OurServices from "./Components/OurServices/OurServices";
import {
  ABOUT_US,
  CAREER_CONNECT,
  HIRE_US,
  JOIN_US,
  KIVU_TECH_CORNER,
  MEET_OUR_TEAM,
  MENTORSHIP,
  OUR_COMPANY,
  OUR_SERVICES,
  PARTNERS_AND_CLIENTS,
  TECHNOLOGIES
} from "./Components/pageRoutes";
import AboutUs from "./Components/AboutUs/AboutUs";
import Technologies from "./Components/Technologies/Technologies";
import Hireus from "./Components/Hireus/Hireus";
import Joinus from "./Components/Joinus/Joinus";
import KivuTechCorner from "./Components/KivuTechCorner/KivuTechCorner";
import Mentorship from "./Components/Mentorship/Mentorship";
import CareerConnect from "./Components/CareerConnect/CareerConnect";
import MeetOurTeam from "./Components/OurTeam/MeetOurTeam";
import PartnerClients from "./Components/PartnersClients/PartnersClients";
import Footer from "./Components/Footer/Footer";
import { THEME } from "./theme";

// eslint-disable-next-line @typescript-eslint/no-require-imports
const classNames = require("classnames");

const App = () => {
  const theme = useContext(PageThemeContext);
  const pageWrapperClass = classNames("pageWrapper", `page-theme-${theme}`);

  const { pathname } = useLocation();
  const queryClient = new QueryClient();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <QueryClientProvider client={queryClient}>
      <PageThemeContext.Provider value={THEME.LIGHT}>
        <section className={pageWrapperClass}>
          <Header />
          <section id="pageContent" className={`page-theme-${theme}`}>
            <Routes>
              <Route path={PARTNERS_AND_CLIENTS} element={<PartnerClients />} />
              <Route path={MEET_OUR_TEAM} element={<MeetOurTeam />} />
              <Route path={OUR_COMPANY} element={<AboutUs />} />
              <Route path={CAREER_CONNECT} element={<CareerConnect />} />
              <Route path={MENTORSHIP} element={<Mentorship />} />
              <Route path={KIVU_TECH_CORNER} element={<KivuTechCorner />} />
              <Route path={JOIN_US} element={<Joinus />} />
              <Route path={HIRE_US} element={<Hireus />} />
              <Route path={TECHNOLOGIES} element={<Technologies />} />
              <Route path={ABOUT_US} element={<AboutUs />} />
              <Route path={OUR_SERVICES} element={<OurServices />} />
              <Route path="/" element={<Homepage />} />
            </Routes>
          </section>
          <Footer />
        </section>
      </PageThemeContext.Provider>
    </QueryClientProvider>
  );
};

export default App;
