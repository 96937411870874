export enum EmploymentPosition {
  SDE = "SDE",
  HR = "HR",
  PM = "PM",
  ACCOUNTANT = "ACCOUNTANT",
  CUSTOMER_SERVICE = "CUSTOMER_SERVICE"
}

export enum EmploymentPositionLevel {
  STAFF = "STAFF",
  SENIOR = "SENIOR",
  JUNIOR = "JUNIOR",
  INTERN = "INTERN"
}
export enum EmploymentType {
  EMPLOYEE = "EMPLOYEE",
  STAFF = "STAFF",
  ADMIN = "ADMIN"
}

export enum EducationDegree {
  HIGH_SCHOOL = "HIGH_SCHOOL",
  BACHELORS = "BACHELORS",
  MASTERS = "MASTERS",
  ASSOCIATE = "ASSOCIATE",
  PHD = "PHD"
}

export enum EmploymentDuration {
  FULL_TIME = "FULL_TIME",
  PART_TIME = "PART_TIME"
}

export enum PermissionRole {
  EMPLOYEE = "EMPLOYEE",
  STAFF = "STAFF",
  ADMIN = "ADMIN",
  ANONYMOUS = "ANONYMOUS",
  MEMBER = "MEMBER"
}

export enum EmployeeResignReasons {
  AT_WILL = "AT_WILL",
  PERFORMANCE = "PERFORMANCE",
  BUDGET_CUT = "BUDGET_CUT"
}

export enum EmployeeHireStatus {
  ON_HOLD = "ON_HOLD",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  BLOCKED = "BLOCKED"
}

export interface Address {
  country: string;
  province: string;
  district: string;
  sector: string;
  cell: string;
  village: string;
}

export interface AdditionalEmployeeInfo {
  githubUrl?: string;
  linkedInUrl?: string;
  portfolioUrl?: string;
  schoolName: string;
  degree: EducationDegree;
  concentration: string;
}

export interface HiredEmployeeInfo {
  avatar: string;
  positions: EmploymentPosition[];
  positionLevel: EmploymentPositionLevel;
  employmentType: EmploymentType;
  employmentDuration: EmploymentDuration;
  dateOfBirth: Date;
  hireDate: Date;
  rehireDate?: Date;
  resignDate?: Date;
  resignReason?: EmployeeResignReasons;
  address: Address;
  hireStatus: EmployeeHireStatus;
  isVerified: boolean;
}

export interface Employee {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: PermissionRole;
  alias: string;
  additionalEmployeeInfo: AdditionalEmployeeInfo;
  hiredEmployeeInfo: HiredEmployeeInfo;
}

export interface GetEmployeesResponse {
  getAllPublicEmployees: Employee[];
}

export interface GetEmployeeInfoResponse {
  getEmployeeById: Employee;
}
