import React, { useContext, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Homepage from "./Components/Homepage";
import Header from "./Components/Header/Header";
import PageThemeContext from "./Contexts/pageThemeContext";
import OurServices from "./Components/OurServices/OurServices";
import {
  ABOUT_US,
  CAREER_CONNECT,
  HIRE_US,
  JOBS,
  JOIN_US,
  KIVU_TECH_CORNER,
  LOGIN,
  LOGOUT,
  MEET_OUR_TEAM,
  MENTORSHIP,
  OUR_COMPANY,
  OUR_SERVICES,
  PARTNERS_AND_CLIENTS,
  REGISTRATION,
  SURVEY_FORM,
  TECHNOLOGIES,
  VERIFY_OTP
} from "./Components/pageRoutes";
import AboutUs from "./Components/AboutUs/AboutUs";
import Technologies from "./Components/Technologies/Technologies";
import Hireus from "./Components/Hireus/Hireus";
import Joinus from "./Components/Joinus/Joinus";
import KivuTechCorner from "./Components/KivuTechCorner/KivuTechCorner";
import Mentorship from "./Components/Mentorship/Mentorship";
import CareerConnect from "./Components/CareerConnect/CareerConnect";
import MeetOurTeam from "./Components/OurTeam/MeetOurTeam";
import PartnerClients from "./Components/PartnersClients/PartnersClients";
import Footer from "./Components/Footer/Footer";
import { THEME } from "./theme";
import SurveyForm from "./Components/Survey/SurveyForm";
import Login from "./Components/Auth/Login";
import VerifyOtp from "./Components/Auth/VerifyOtp";
import Registration from "./Components/Auth/Registration";
import Logout from "./Components/Auth/Logout";
import JobDetail from "./Components/Career/JobDetail";
import JobList from "./Components/Career/JobList";

// eslint-disable-next-line @typescript-eslint/no-require-imports
const classNames = require("classnames");

const App = () => {
  const theme = useContext(PageThemeContext);
  const pageWrapperClass = classNames("pageWrapper", `page-theme-${theme}`);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const isAuthFlow =
    pathname === REGISTRATION ||
    pathname === LOGIN ||
    pathname === VERIFY_OTP ||
    pathname === LOGOUT;

  return (
    <PageThemeContext.Provider value={THEME.LIGHT}>
      <section className={pageWrapperClass}>
        <Header hideNavLinks={isAuthFlow} />
        <section id="pageContent" className={`page-theme-${theme}`}>
          <Routes>
            <Route path={PARTNERS_AND_CLIENTS} element={<PartnerClients />} />
            <Route path={MEET_OUR_TEAM} element={<MeetOurTeam />} />
            <Route path={OUR_COMPANY} element={<AboutUs />} />
            <Route path={CAREER_CONNECT} element={<CareerConnect />} />
            <Route path={MENTORSHIP} element={<Mentorship />} />
            <Route path={KIVU_TECH_CORNER} element={<KivuTechCorner />} />
            <Route path={JOIN_US} element={<Joinus />} />
            <Route path={HIRE_US} element={<Hireus />} />
            <Route path={TECHNOLOGIES} element={<Technologies />} />
            <Route path={ABOUT_US} element={<AboutUs />} />
            <Route path={OUR_SERVICES} element={<OurServices />} />
            <Route path={SURVEY_FORM} element={<SurveyForm />} />
            <Route path={LOGIN} element={<Login />} />
            <Route path={REGISTRATION} element={<Registration />} />
            <Route path={VERIFY_OTP} element={<VerifyOtp />} />
            <Route path={LOGOUT} element={<Logout />} />
            <Route path={`${JOBS}/:jobId`} element={<JobDetail />} />
            <Route path={JOBS} element={<JobList />} />
            <Route path="/" element={<Homepage />} />
          </Routes>
        </section>
        <Footer hideFooterLinks={isAuthFlow} />
      </section>
    </PageThemeContext.Provider>
  );
};

export default App;
