import React, { FC, useContext, useState } from "react";
import { Button, Grid, LinearProgress, Stack, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import PageContent from "../Common/PageContent/PageContent";
import YouTubeVideo from "../Common/YoutubeVideo/YoutubeVideo";
import ServiceCard from "./ServiceCard";
import Modal from "../Common/Modal/Modal";
import PageHeading from "../Common/PageHeading/PageHeading";
import ServiceChatBookingForm from "./ServiceChatBookingForm";
import { fetchGraphQL } from "../../Graphql/utils";
import { getAllServiceCategoriesResponse } from "../../Graphql/types/serviceCategory";
import { getAllServiceCategoriesQuery } from "../../Graphql/querries/serviceCategoryQuery";
import AlertBannerContext from "../../Contexts/alertBannerContext";
import { JOBS } from "../pageRoutes";

export const OurServices: FC = () => {
  const theme = useTheme();
  const matchMD = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { setAlert } = useContext(AlertBannerContext);
  const navigate = useNavigate();

  const { data, isFetching } = useQuery<getAllServiceCategoriesResponse>({
    queryKey: ["addServiceChatBooking"],
    queryFn: () => fetchGraphQL(getAllServiceCategoriesQuery, {}, setAlert)
  });

  return (
    <section>
      <PageHeading titleText="Our Services" />
      <PageContent styles={{ background: "white" }}>
        {/* <Typography
          fontSize="1.75em"
          gutterBottom
          variant="h4"
          align="center"
          sx={{ marginBottom: 3, marginTop: 3 }}
        >
          What services do we offer?
        </Typography> */}
        <Typography
          variant="subtitle1"
          component="div"
          align="center"
          gutterBottom
          style={{ margin: "3em 0px", textAlign: "center" }}
        >
          At Kivunova, we offer mentorship programs for students, graduates, and
          aspiring engineers, helping them build strong foundations in tech.
          Additionally, we provide outsourcing services to institutions and
          businesses, delivering expert tech solutions to meet their unique
          needs.
        </Typography>
        <Stack
          display="flex"
          flexDirection={matchMD ? "column" : "row"}
          paddingTop="1em"
        >
          <Stack
            display="flex"
            flexDirection="column"
            width={matchMD ? "100%" : "50%"}
            padding={matchMD ? 0 : "2em 0"}
            boxSizing="border-box"
            gap="1.5em"
          >
            <Typography
              gutterBottom
              variant="h6"
              fontWeight="regular"
              fontSize="1.3em"
            >
              We foster in uplifting skills through mentorship in tech and
              innovation
            </Typography>
            <Typography>
              Join us today and unleash your potential in tech through
              interactive mentorship and training program. You will also get
              opportunity to connect with high tech engineers who are practicing
              accross different corners of the world.
            </Typography>
            <Stack
              width="100%"
              marginTop="2em"
              flexDirection="row"
              justifyContent="center"
            >
              <Button
                variant="outlined"
                onClick={() => navigate(JOBS)}
                size="medium"
              >
                Expore open opportunities
              </Button>
            </Stack>
          </Stack>
          <Stack width={matchMD ? "100%" : "50%"} padding="2em 0">
            <Container>
              <YouTubeVideo videoId="dQw4w9WgXcQ" />
            </Container>
          </Stack>
        </Stack>
        <Stack
          display="flex"
          flexDirection={matchMD ? "column" : "row-reverse"}
          paddingTop="1em"
        >
          <Stack
            display="flex"
            flexDirection="column"
            width={matchMD ? "100%" : "50%"}
            padding={matchMD ? 0 : "2em 0"}
            boxSizing="border-box"
            gap="1.5em"
          >
            <Typography
              gutterBottom
              variant="h6"
              fontWeight="regular"
              fontSize="1.3em"
            >
              We partner with you through every stage of your journey
            </Typography>
            <Typography>
              Build your dedicated remote team, with full control and get your
              deliverable save up to 70% on employees costs.
            </Typography>
            <Stack
              width="100%"
              marginTop="2em"
              flexDirection="row"
              justifyContent="center"
            >
              <Button onClick={handleOpen} size="medium" variant="contained">
                Talk to our experts
              </Button>
              {open && (
                <Modal
                  title="Book a chat with out team today!"
                  closeButton={handleClose}
                >
                  <ServiceChatBookingForm />
                </Modal>
              )}
            </Stack>
          </Stack>
          <Stack width={matchMD ? "100%" : "50%"} padding="2em 0">
            <Container>
              <YouTubeVideo videoId="dQw4w9WgXcQ" />
            </Container>
          </Stack>
        </Stack>
        <Grid container spacing={3} flexDirection="column">
          {isFetching && (
            <Stack
              width="100%"
              paddingBottom="1em"
              display="flex"
              justifyContent="center"
            >
              <LinearProgress />
            </Stack>
          )}
          {data && (
            <Typography
              variant="h6"
              fontSize="1.5em"
              fontWeight="bold"
              padding="2em"
              width="100%"
              textAlign="center"
              boxSizing="border-box"
            >
              Area of expertise
            </Typography>
          )}
          <Grid
            container
            spacing={2}
            padding="0 2em"
            justifyContent="center"
            alignItems="center"
            margin={0}
          >
            {data?.getAllServiceCategories?.map((service) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={service.title}
                sx={{ padding: "8px" }}
              >
                <ServiceCard service={service} handleChatBooking={handleOpen} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </PageContent>
    </section>
  );
};

export default OurServices;
