import React from "react";
import { Box, Typography, Paper, Chip, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { JOBS } from "../pageRoutes";

interface JobCardProps {
  title: string;
  company: string;
  location: string;
  salary: string;
  timePosted: string;
  type: string[];
  jobId: string;
}

const JobCard: React.FC<JobCardProps> = ({
  title,
  company,
  location,
  salary,
  timePosted,
  type,
  jobId
}) => {
  const navigate = useNavigate();

  const handleToJobDetail = (id: string) => {
    navigate(`${JOBS}/${id}`);
  };

  return (
    <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        width="100%"
        gap={3}
      >
        <Box width="100%">
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body2" color="text.secondary">
            {company} • {location}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {timePosted}
          </Typography>
          <Box mt={1}>
            {type.map((tag) => (
              <Chip
                variant="outlined"
                label={tag}
                size="small"
                sx={{ mr: 1, padding: 1 }}
                color={tag === "Fulltime" ? "success" : "primary"}
              />
            ))}
          </Box>
        </Box>
        <Divider orientation="horizontal" variant="middle" flexItem />
        <Box
          width="100%"
          flexDirection="row"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body1" color="primary">
            {salary}/m
          </Typography>
          <Chip
            label="View details..."
            sx={{ padding: 1.5 }}
            color="info"
            onClick={() => {
              handleToJobDetail(jobId);
            }}
            variant="outlined"
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default JobCard;
