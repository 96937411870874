import React, { useState } from "react";
import EventModal from "./EventModal";
import { Event } from "./types";

const CalendarView: React.FC<{ events: Event[] }> = () => {
  const [selectedEvent, setSelectedEvent] = useState<Event | undefined>();

  return (
    <div className="calendar-view">
      <EventModal
        event={selectedEvent}
        onClose={() => setSelectedEvent(undefined)}
      />
    </div>
  );
};

export default CalendarView;
