export const getAllEmployeesQuery = `
query GetAllPublicEmployees {
    getAllPublicEmployees {
        firstName
        lastName
        email
        hiredEmployeeInfo {
            avatar
            positions
            positionLevel
        }
    }
}
`;

export const getEmployeeByIdQuery = `
query GetEmployeeById($id: ID!) {
    getEmployeeById(id: $id) {
       id
        firstName
        lastName
        email
        phoneNumber
        role
        hiredEmployeeInfo {
            avatar
            address {
                country
                province
                district
                sector
                cell
                village
            }
        }
        additionalEmployeeInfo {
            linkedInUrl
            portfolioUrl
            githubUrl
            schoolName
            degree
            concentration
        }
    }
}
`;
