import React from "react";
import {
  Button,
  Card,
  CardContent,
  Container,
  Typography
} from "@mui/material";
import "./styles/serviceCard.scss";
import { ServiceCategory } from "../../Graphql/types/serviceCategory";

interface ServiceCardProps {
  service: ServiceCategory;
  handleChatBooking: () => void;
}

const ServiceCard: React.FC<ServiceCardProps> = (props) => {
  const { service, handleChatBooking } = props;

  return (
    <Card className="service-card">
      <CardContent className="card-content">
        <Container className="card-front">
          <Typography
            variant="h6"
            fontWeight="bold"
            component="div"
            className="service-name"
          >
            {service.title}
          </Typography>
        </Container>
        <Container className="card-back">
          <Typography variant="body2" className="c">
            {service.description}
          </Typography>
          <Container style={{ paddingTop: "2em" }}>
            <Button
              className="serviceCardLink"
              onClick={handleChatBooking}
              variant="outlined"
            >
              Chat with experts
            </Button>
          </Container>
        </Container>
      </CardContent>
    </Card>
  );
};

export default ServiceCard;
