import { GraphQLResponse } from "./types/common";

export const fetchGraphQL = async <T, E>(
  query: string,
  variables: E
): Promise<T> => {
  const grapqlEnpoint = process.env.REACT_APP_GQL_ENDPOINT;
  const response = await fetch(grapqlEnpoint || "", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ query, variables })
  });

  const result: GraphQLResponse<T> = await response.json();

  if (result.errors) {
    throw new Error(result.errors.map((error) => error.message).join("\n"));
  }

  return result.data;
};
