export const createApplicationQuery = `
mutation CreateApplication($input: CreateApplicationInput!, $file: Upload!) {
    createApplication(input: $input, file: $file) {
      application {
        id
        jobId
        memberId
        status
        lastUpdated
        submittedDate
        lastUpdateBy
        linkedInUrl
        portifolioUrl
      }
    }
}
`;
