import React from "react";
import { Card, CardContent, Container, Link, Typography } from "@mui/material";
import "./styles/technologyCard.scss";
import { TechnologyItem } from "../../Graphql/types/technology";

interface TechnologyCardProps {
  technology: TechnologyItem;
}

const TechnologyCard: React.FC<TechnologyCardProps> = (props) => {
  const { technology } = props;

  return (
    <Card className="technology-card">
      <CardContent className="card-content">
        <Container className="card-front">
          <Typography
            variant="h5"
            fontWeight="bold"
            component="div"
            className="technology-name"
          >
            {technology.title}
          </Typography>
        </Container>
        <Container className="card-back">
          <Typography variant="body2" className="technology-details">
            {technology.description}
          </Typography>
          <Container>
            <Link
              target="_blank"
              rel="noreferrer"
              className="technologyCardLink"
              underline="hover"
              href={technology.detailUrl}
            >
              {" "}
              Read more...
            </Link>
          </Container>
        </Container>
      </CardContent>
    </Card>
  );
};

export default TechnologyCard;
