import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import PageHeading from "../Common/PageHeading/PageHeading";
import PageContent from "../Common/PageContent/PageContent";

export const AboutUs: FC = () => {
  return (
    <section>
      <PageHeading titleText="Explore Who we are, What we do and Why we operate" />
      <PageContent styles={{ background: "white", paddingTop: "2em" }}>
        {/* Who We Are Section */}
        <Box sx={{ marginBottom: 6 }}>
          <Typography
            variant="h6"
            gutterBottom
            style={{ margin: "20px 0px", textAlign: "center" }}
          >
            Who We Are
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            style={{ margin: "3em 0px", textAlign: "center" }}
          >
            We are a tech hub focused on empowering students, recent graduates,
            and aspiring engineers through mentorship programs. Our goal is to
            help individuals build their technical skills and become leaders in
            the technology sector. In addition to education, we offer
            outsourcing services to businesses.
          </Typography>
        </Box>

        {/* Our Mission Section */}
        <Box sx={{ marginBottom: 6 }}>
          <Typography
            variant="h6"
            gutterBottom
            style={{ margin: "20px 0px", textAlign: "center" }}
          >
            Our Mission
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            style={{ margin: "3em 0px", textAlign: "center" }}
          >
            Our mission is to bridge the gap between education and real-world
            tech experience. We aim to equip future engineers with the tools
            they need to succeed in a fast-paced, ever-evolving industry while
            providing institutions with reliable tech outsourcing solutions.
          </Typography>
        </Box>

        {/* Our Values Section */}
        <Box>
          <Typography
            variant="h6"
            gutterBottom
            style={{ margin: "20px 0px", textAlign: "center" }}
          >
            Our Values
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            style={{ margin: "3em 0px", textAlign: "center" }}
          >
            We believe in innovation, continuous learning, and integrity. Our
            focus is on creating a positive impact in the tech community through
            collaboration, inclusivity, and providing opportunities for growth
            to all aspiring tech professionals.
          </Typography>
        </Box>
      </PageContent>
    </section>
  );
};

export default AboutUs;
