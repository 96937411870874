export const sendOtpQuery = `
  mutation SendOtp($input: SendOtpInput!) {
    sendOtp(input: $input) {
      status
    }
  }
`;

export const reSendOtpQuery = `
  mutation ReSendOtp($input: SendOtpInput!) {
    reSendOtp(input: $input) {
      status
    }
  }
`;

export const validateOtpQuery = `
 mutation ValidateOtp($input: ValidateOtpInput!) {
      validateOtp(input: $input) {
        status
        accessToken
        token
    }
 }
`;

export const authorizeQuery = `
 mutation Authorize {
      authorize {
        status
        userId
        accessToken
    }
 }
`;

export const logoutQuery = `
 mutation Logout {
      logout {
        status
    }
 }
`;

export const registerQuery = `
 mutation AddMember($input: CreateMemberInput!) {
      addMember(input: $input) {
        id
        firstName
        email
        phoneNumber
        lastName
    }
 }
`;
