import React, { ReactNode, useEffect, useRef } from "react";
import "./styles/horizontalScroll.scss";

interface HorizontalScrollProps {
  children: ReactNode;
}

const HorizontalScroll: React.FC<HorizontalScrollProps> = (props) => {
  const { children } = props;
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainerRef.current) {
        const { scrollLeft } = scrollContainerRef.current;
        const cards =
          scrollContainerRef.current.querySelectorAll<HTMLDivElement>(
            ".team-member-card"
          );
        cards.forEach((card) => {
          // eslint-disable-next-line no-param-reassign
          card.style.transform = `translateX(${scrollLeft * 0.2}px)`; // Parallax effect
        });
      }
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <div className="horizontal-scroll-container" ref={scrollContainerRef}>
      <div className="horizontal-scroll-content">{children}</div>
    </div>
  );
};

export default HorizontalScroll;
