import React, {
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useMutation } from "@tanstack/react-query";
import AuthContext from "../Contexts/authContext";
import { AUTH_STATUS, AuthorizeResponse } from "../Graphql/types/auth";
import { fetchGraphQL } from "../Graphql/utils";
import { authorizeQuery } from "../Graphql/querries/authQuery";
import {
  AuthContextInfo,
  AuthContextType
} from "../Contexts/types/authContextType";
import { LOCAL_STORAGE_KEYS } from "../Enums/localStorage";
import { setLocalStorageElement } from "../Utils/localStorageUtils";
import AlertBannerContext from "../Contexts/alertBannerContext";

const AuthProviders: FC<{ children: ReactNode }> = ({ children }) => {
  const { setAlert } = useContext(AlertBannerContext);
  const [authContextInfo, updateAuthContextInfo] = useState<AuthContextInfo>({
    auth: { status: AUTH_STATUS.UNAUTHENTICATED }
  });

  const { mutate } = useMutation<AuthorizeResponse, Error>({
    mutationKey: ["authorize"],
    mutationFn: () => fetchGraphQL(authorizeQuery, { input: null }, setAlert),
    onSuccess: (response) => {
      const { status, accessToken, userId: employeeId } = response.authorize;
      if (status !== AUTH_STATUS.AUTHENTICATED) {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
      } else if (accessToken)
        setLocalStorageElement(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, accessToken);

      updateAuthContextInfo({
        ...authContextInfo,
        auth: { status, employeeId }
      });
    }
  });

  useEffect(() => {
    mutate();
  }, [authContextInfo.auth.status]);

  const authInfo = useMemo((): AuthContextType => {
    return { authContextInfo, updateAuthContextInfo };
  }, [authContextInfo]);

  return (
    <AuthContext.Provider value={authInfo}>{children}</AuthContext.Provider>
  );
};

export default AuthProviders;
