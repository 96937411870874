export const getAllEmployeesQuery = `
query GetAllEmployees {
    getAllEmployees {
        firstName
        lastName
        linkedin
        avatar
        positions
        email
    }
}
`;
