import { ALERT_BANNER_CODE } from "../Enums/alertCode";

export const AlertBannerCodeMap: Record<ALERT_BANNER_CODE, string> = {
  [ALERT_BANNER_CODE.INTERNAL_SERVER_ERROR]:
    "Something went wrong, please try again.",
  [ALERT_BANNER_CODE.FAIL_TO_SEND_EMAIL]:
    "We are unable to send OTP code. Please verify your email and try again or refresh the browser.",
  [ALERT_BANNER_CODE.ACCESS_DENIED]:
    "Access Denied, please refresh the browser or login and try again .",
  [ALERT_BANNER_CODE.ALREADY_APPLIED]: " You have already applied to this job",
  [ALERT_BANNER_CODE.FAILED_TO_UPLOAD_FILE_TO_S3]:
    "Something went wrong while uploading your file, please refresh the browser and try again.",
  [ALERT_BANNER_CODE.INVALID_OR_UNPOSTED_JOB]:
    "This job does not exist or it's unposted. Please try a different job.",
  [ALERT_BANNER_CODE.INVALID_OTP]:
    "OTP provided is invalid. Please check your email or resend OTP again.",
  [ALERT_BANNER_CODE.MEMBER_NOT_FOUND]:
    "Email doesn't exist, please register or sign in with a different email",
  [ALERT_BANNER_CODE.NOT_FOUND]:
    "Resource not found, please refresh the browser and try again",
  [ALERT_BANNER_CODE.INTERNAL_ERROR]: "Something went wrong, please try again.",
  [ALERT_BANNER_CODE.WITHDRAWN_APPLICATION]:
    "Successfully withdrawn the application",
  [ALERT_BANNER_CODE.DUPLICATE_KEYS]:
    "Information already in use. Please try with different information."
};
