import React, { FC } from "react";
import { Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Sidenavigation from "./Sidenavigation";
import TopNavigation from "./TopNavigation";
import { NavigationConfigList } from "./config/navigationConfig";

const Navigation: FC = () => {
  const theme = useTheme();
  const matchMD = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container id="navigationContainer">
      {matchMD ? (
        <Sidenavigation />
      ) : (
        <TopNavigation navConfigList={NavigationConfigList} />
      )}
    </Container>
  );
};

export default Navigation;
