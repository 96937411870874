import React, { useState } from "react";
import { Box, Grid, TextField, IconButton, Chip, Divider } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import SearchIcon from "@mui/icons-material/Search";
import FilterSidebar from "./FilterSidebar";
import JobCard from "./JobCard";

const jobs = [
  {
    title: "UI/UX Designer",
    company: "Kivunova",
    location: "Musanze",
    salary: "$1000",
    timePosted: "2 days ago",
    type: ["Fulltime", "Hybrid", "2-4 Years"],
    jobId: "1"
  },
  {
    title: "Product Designer",
    company: "Kivunova",
    location: "Musanze",
    salary: "$1500",
    timePosted: "1 hour ago",
    type: ["Fulltime", "Onsite", "2-4 Years"],
    jobId: "2"
  },
  {
    title: "Product Designer",
    company: "Kivunova",
    location: "Musanze",
    salary: "$1500",
    timePosted: "1 hour ago",
    type: ["Fulltime", "Onsite", "2-4 Years"],
    jobId: "3"
  },
  {
    title: "Product Designer",
    company: "Kivunova",
    location: "Musanze",
    salary: "$1500",
    timePosted: "1 hour ago",
    type: ["Fulltime", "Onsite", "2-4 Years"],
    jobId: "4"
  }
];

const JobList: React.FC = () => {
  const [mobileFilterOpen, setMobileFilterOpen] = useState<boolean>(false);
  return (
    <Box sx={{ display: "flex" }}>
      {/* Sidebar */}
      <FilterSidebar
        mobileOpen={mobileFilterOpen}
        setMobileOpen={setMobileFilterOpen}
      />
      <Divider orientation="vertical" variant="middle" flexItem />
      {/* Main Content */}
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={{ sm: 0, md: 0, lg: 0 }}
        >
          <TextField
            placeholder="Search for jobs"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              )
            }}
            sx={{ width: "60%" }}
          />

          <Chip
            sx={{
              display: { sm: "flex", md: "none", lg: "none" },
              padding: "1em"
            }}
            icon={<TuneIcon fontSize="small" />}
            label="Filters"
            variant="outlined"
            color="primary"
            onClick={() => setMobileFilterOpen(true)}
          />
        </Box>

        <Grid
          container
          spacing={{ sm: 0, md: 3, lg: 3 }}
          sx={{ width: "100%", margin: "auto", paddingTop: 3 }}
        >
          {jobs.map((job) => (
            <Grid item xs={12} md={6} lg={4} key={job.title}>
              <JobCard {...job} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default JobList;
