import React, { FC } from "react";
import PageHeading from "../Common/PageHeading/PageHeading";
import PageContent from "../Common/PageContent/PageContent";

export const Mentorship: FC = () => {
  return (
    <section>
      <PageHeading titleText="Explore our mentorship program" />
      <PageContent>
        <section />
      </PageContent>
    </section>
  );
};

export default Mentorship;
