export const createApplicationQuery = `
mutation CreateApplication($input: CreateApplicationInput!, $file: Upload!) {
    createApplication(input: $input, file: $file) {
      application {
        id
        jobId
        employeeId
        previousStatus
        status
      }
    }
}
`;

export const getApplicationListByEmployeeIdQuery = `
query GetApplicationListByEmployeeId($employeeId: String!) {
    getApplicationListByEmployeeId(employeeId: $employeeId) {
        id
        jobId
        employeeId
        status
        submittedDate
        previousStatus
        jobInfo {
          jobTitle
          jobLocation
          totalPay
          jobRole
          jobLevel
          jobType
          duration
          currencyCode
        }
    }
}
`;

export const getApplicationListWithFilterQuery = `
query GetApplicationListWithFilters($input: getApplicationListWithFiltersInput!) {
    getApplicationListWithFilters(input: $input) {
        id
        jobId
        employeeId
        status
        submittedDate
        previousStatus
        jobInfo {
          jobTitle
          jobLocation
          totalPay
          jobRole
          jobLevel
          jobType
          duration
          currencyCode
        }
    }
}
`;

export const withdrawApplicationQuery = `
mutation WithdrawApplication($input: WithdrawApplicationInput!) {
    withdrawApplication(input: $input) {
        status
        application {
            id
            jobId
            employeeId
            status
            submittedDate
            previousStatus
            jobInfo {
                jobTitle
                jobLocation
                totalPay
                jobRole
                jobLevel
                jobType
                duration
                currencyCode
            }
        }
    }
}
`;
