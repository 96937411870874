import React, { FC } from "react";
import { Grid, Typography } from "@mui/material";
import PageHeading from "../Common/PageHeading/PageHeading";
import PageContent from "../Common/PageContent/PageContent";
import { backendTechnologies, frontendTechnologies } from "./constants";
import TechnologyCard from "./TechnologyCard";

import "./styles/index.scss";

export const Technologies: FC = () => {
  return (
    <section>
      <PageHeading titleText="Our Technologies" />
      <PageContent styles={{ background: "white" }}>
        {/* <Typography variant="h4" gutterBottom style={{ margin: '20px 0px', textAlign: 'center' }}>
                    Our Technologies
                </Typography> */}
        <Typography
          variant="body1"
          gutterBottom
          style={{ margin: "3em 0px", textAlign: "center" }}
        >
          At Kivunova, we pride ourselves on leveraging a diverse array of
          cutting-edge technologies to deliver robust and scalable solutions for
          our clients. Our commitment to excellence and innovation is reflected
          in the tools and frameworks we utilize, ensuring that our projects are
          built to the highest standards. Below, we have outlined the
          technologies we specialize in, categorized into frontend and backend
          technologies, to give you a comprehensive view of our technical
          expertise.
        </Typography>

        <Typography
          aria-label="Frontend Technologies title"
          variant="h6"
          gutterBottom
          style={{ margin: "20px 0px", textAlign: "center" }}
        >
          Frontend Technologies
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          style={{ margin: "3em 0px", textAlign: "center" }}
          aria-label="Frontend Technologies details"
        >
          In the realm of frontend development, our team excels in creating
          dynamic and interactive user interfaces that are not only visually
          appealing but also highly functional. We employ a variety of
          technologies to achieve this, ensuring that each application we
          develop offers an exceptional user experience.
        </Typography>
        <Grid container spacing={2}>
          {frontendTechnologies.map((tech) => (
            <Grid item xs={12} sm={6} md={4} key={tech.name}>
              <TechnologyCard technology={tech} />
            </Grid>
          ))}
        </Grid>

        <Typography
          variant="h6"
          gutterBottom
          style={{ margin: "20px 0px", textAlign: "center" }}
        >
          Backend Technologies
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          style={{ margin: "3em 0px", textAlign: "center" }}
        >
          On the backend, we focus on building secure, efficient, and scalable
          systems that power our applications. Our expertise in various backend
          technologies allows us to choose the right tools for each project,
          ensuring optimal performance and reliability.
        </Typography>
        <Grid container spacing={2}>
          {backendTechnologies.map((tech) => (
            <Grid item xs={12} sm={6} md={4} key={tech.name}>
              <TechnologyCard technology={tech} />
            </Grid>
          ))}
        </Grid>
      </PageContent>
    </section>
  );
};

export default Technologies;
