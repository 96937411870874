import React from "react";
import {
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@mui/material";
import { ServiceItem } from "../../Graphql/types/serviceCategory";

// Define props type for the RoleCard component
interface ServiceCardProps {
  title: string;
  icon: string;
  serviceList: ServiceItem[];
}

// RoleCard component to render each card
const ServiceCard: React.FC<ServiceCardProps> = ({
  title,
  icon,
  serviceList
}) => (
  <Card
    variant="outlined"
    sx={{ height: "100%" }}
    style={{ background: "linear-gradient(114deg, #fff, #a0cee975)" }}
  >
    <CardContent>
      <Grid container direction="column" alignItems="center">
        <Grid item>{icon}</Grid>
        <Grid item>
          <Typography
            variant="h6"
            component="div"
            align="center"
            fontWeight="regular"
          >
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <List>
            {serviceList.map((service) => (
              <ListItem key={service.title}>
                <ListItemText primary={service.title} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default ServiceCard;
