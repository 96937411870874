export const frontendTechnologies = [
  {
    name: "HTML",
    details:
      "The backbone of any web application, HTML provides the structure for our web pages. We use HTML to ensure that our applications are well-organized and accessible.",
    link: "https://developer.mozilla.org/en-US/docs/Web/HTML"
  },
  {
    name: "CSS",
    details:
      "Styling is a crucial aspect of our frontend development. We use CSS to create visually appealing layouts and ensure that our applications are responsive across different devices and screen sizes.",
    link: "https://developer.mozilla.org/en-US/docs/Web/CSS"
  },
  {
    name: "JavaScript",
    details:
      "As a fundamental building block of modern web development, JavaScript allows us to create interactive elements and enhance user engagement. Our developers are adept at using JavaScript to build responsive and dynamic web pages.",
    link: "https://developer.mozilla.org/en-US/docs/Web/JavaScript"
  },
  {
    name: "TypeScript",
    details:
      "Building on JavaScript, TypeScript introduces static types, which help us catch errors early and improve code quality. This leads to more maintainable and scalable applications.",
    link: "https://www.typescriptlang.org/"
  },
  {
    name: "React",
    details:
      "A powerful library for building user interfaces, React enables us to create reusable UI components. This not only speeds up development but also ensures a consistent look and feel across our applications.",
    link: "https://reactjs.org/"
  },
  {
    name: "Angular",
    details:
      "Angular is a robust framework for building complex single-page applications. It provides a comprehensive solution for developing large-scale applications with a focus on performance and scalability.",
    link: "https://angular.io/"
  },
  {
    name: "Next.js",
    details:
      "For server-side rendering and static site generation, we use Next.js. This framework allows us to build high-performance applications with improved SEO and faster load times.",
    link: "https://nextjs.org/"
  }
];

export const backendTechnologies = [
  {
    name: "Node",
    details:
      "With Node.js, we can build fast and scalable server-side applications using JavaScript. It is particularly well-suited for real-time applications and API development.",
    link: "https://nodejs.org/"
  },
  {
    name: "Java",
    details:
      "Known for its robustness and portability, Java is a go-to language for building enterprise-level applications. Our experience with Java allows us to develop high-performance backend systems.",
    link: "https://docs.oracle.com/en/java/"
  },
  {
    name: "C#",
    details:
      "For applications that run on the .NET framework, we use C#. This language is ideal for building secure and scalable web applications, particularly in a Microsoft environment.",
    link: "https://docs.microsoft.com/en-us/dotnet/csharp/"
  },
  {
    name: "Kotlin",
    details:
      "Kotlin is a statically-typed programming language that runs on the Java Virtual Machine (JVM). It is interoperable with Java and offers modern features that improve developer productivity.",
    link: "https://kotlinlang.org/"
  },
  {
    name: "Go (Golang)",
    details:
      "Go, also known as Golang, is a statically-typed language developed by Google. It emphasizes simplicity, efficiency, and concurrency. Go is ideal for building scalable and high-performance applications.",
    link: "https://golang.org/"
  },
  {
    name: "Python",
    details:
      "Python is a versatile, high-level programming language known for its simplicity and readability. It is widely used for web development, scientific computing, artificial intelligence, and more.",
    link: "https://www.python.org/"
  },
  {
    name: "Spring Boot",
    details:
      "Spring Boot is an extension of the Spring framework that simplifies the development of production-ready applications. It provides a robust platform for building scalable backend services.",
    link: "https://spring.io/projects/spring-boot"
  },
  {
    name: "Flask",
    details:
      "Flask is a lightweight web framework for Python. It is known for its simplicity and flexibility, making it ideal for building small to medium-scale web applications and APIs.",
    link: "https://flask.palletsprojects.com/"
  },
  {
    name: "Django",
    details:
      "Django is a high-level Python web framework that follows the 'batteries-included' philosophy. It is designed for rapid development and provides a comprehensive set of features for building robust web applications.",
    link: "https://www.djangoproject.com/"
  }
];
