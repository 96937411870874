import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  LinearProgress,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { ObjectSchema } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { VerifyOtpFormData } from "./types";
import { fetchGraphQL } from "../../Graphql/utils";
import {
  authorizeQuery,
  reSendOtpQuery,
  validateOtpQuery
} from "../../Graphql/querries/authQuery";
import AuthContext from "../../Contexts/authContext";
import {
  AUTH_STATUS,
  AuthorizeResponse,
  OTP_STATUS,
  ValidateOtpResponse,
  ValidateOtpVariable
} from "../../Graphql/types/auth";
import { LOGIN } from "../pageRoutes";
import { setLocalStorageElement } from "../../Utils/localStorageUtils";
import { LOCAL_STORAGE_KEYS } from "../../Enums/localStorage";
import AlertBannerContext from "../../Contexts/alertBannerContext";
import useQueryParams from "../../Hooks/useQueryParams";

const VerifyOtp: React.FC = () => {
  const { authContextInfo, updateAuthContextInfo } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isResendDisabled, setIsResendDisabled] = useState(true); // Controls button state
  const [timer, setTimer] = useState(300); // Timer starts at 5 minutes (300 seconds)
  const { setAlert } = useContext(AlertBannerContext);
  const { redirectPage } = useQueryParams();

  const schema: ObjectSchema<VerifyOtpFormData> = yup.object().shape({
    otp: yup
      .string()
      .matches(/^\d{6}$/, "Please enter a 6 digits OTP")
      .required("OTP is required")
  });

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<VerifyOtpFormData>({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    // if there is no email, redirect to login directly
    if (!authContextInfo.otp?.email) {
      navigate(LOGIN);
    }
  }, [authContextInfo]);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isResendDisabled && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000); // Update every second
    } else if (timer === 0) {
      setIsResendDisabled(false); // Enable resend button when time is up
      if (interval) clearInterval(interval); // Clear interval
    }

    return () => {
      if (interval) clearInterval(interval); // Cleanup interval on unmount
    };
  }, [isResendDisabled, timer]);

  const { mutate: resendOtp } = useMutation({
    mutationKey: ["reSendOtp"],
    mutationFn: () =>
      fetchGraphQL(
        reSendOtpQuery,
        {
          input: { email: authContextInfo.otp?.email }
        },
        setAlert
      ),
    onSuccess: () => {
      setIsResendDisabled(true); // Disable button again
      setTimer(300); // Reset timer to 5 minutes
    }
  });

  const { mutate: authenticate, isPending: isAuthenticating } = useMutation<
    AuthorizeResponse,
    Error
  >({
    mutationKey: ["authorize"],
    mutationFn: () => fetchGraphQL(authorizeQuery, { input: null }, setAlert),
    onSuccess: (response) => {
      const { status, accessToken } = response.authorize;
      if (status === AUTH_STATUS.AUTHENTICATED) {
        // set authorization data
        if (updateAuthContextInfo) {
          updateAuthContextInfo({
            ...authContextInfo,
            auth: {
              status: AUTH_STATUS.AUTHENTICATED,
              memberId: response.authorize?.userId
            },
            otp: undefined
          });
        }

        // update accessToken if it was expired before
        if (accessToken) {
          setLocalStorageElement(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, accessToken);
        }

        // navigate to the previous page or homepage
        navigate(redirectPage || "/");
      } else {
        // TODO: handle authorization failure based on status
      }
    },
    onError: () => {
      // TODO: handle when authorize fail. probably redirect to login page
    }
  });

  const { mutate: validateOtp, isPending: isValidatingOtp } = useMutation<
    ValidateOtpResponse,
    Error,
    ValidateOtpVariable
  >({
    mutationKey: ["verifyOtp"],
    mutationFn: (variables) =>
      fetchGraphQL(
        validateOtpQuery,
        {
          input: variables
        },
        setAlert
      ),
    onSuccess: (response) => {
      const { token, accessToken, status } = response.validateOtp;
      if (status === OTP_STATUS.VERIFIED) {
        setLocalStorageElement(LOCAL_STORAGE_KEYS.TOKEN, token);
        setLocalStorageElement(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, accessToken);
        authenticate();
      } else {
        // TODO: Handle if code is invalid
      }
    },
    onError: () => {}
  });

  const handleClick = (form: VerifyOtpFormData) => {
    const email = authContextInfo.otp?.email;
    if (email) {
      const request: ValidateOtpVariable = {
        otp: form.otp,
        email
      };

      validateOtp(request);
    }
  };

  const resendOTP = () => {
    resendOtp();
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          borderRadius: 2,
          boxShadow: 3,
          overflow: "hidden",
          flexDirection: { xs: "column", md: "row" }
        }}
      >
        {/* Left Side */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // background: "url(/path-to-image.jpg) no-repeat center",
            backgroundSize: "cover",
            color: "white",
            padding: 4,
            height: { xs: "25vh", md: "auto" }, // Adjust height for small screens
            minHeight: { xs: "20vh", md: "35vh" },
            background: "rgb(34, 47, 62)"
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            gutterBottom
            paddingBottom="1em"
            color="white"
          >
            Verify OTP
          </Typography>
          <Typography
            color="white"
            variant="body1"
            textAlign="center"
            gutterBottom
            fontFamily="none"
          >
            verify OTP code sent to your email
          </Typography>
        </Box>

        {/* Right Side */}
        <Box
          sx={{
            flex: 1,
            background: "white",
            padding: 4
          }}
        >
          <Stack
            minHeight="150px"
            width="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            display="flex"
            spacing="2em"
          >
            <Container
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Stack
                spacing="2em"
                justifyContent="center"
                alignItems="center"
                padding="2em 0px"
                textAlign="center"
                width="100%"
                alignSelf="center"
                component="form"
                onSubmit={handleSubmit(handleClick)}
                noValidate
              >
                <Box
                  component="form"
                  noValidate
                  autoComplete="on"
                  style={{ width: "100%" }}
                >
                  {isAuthenticating ||
                    (isValidatingOtp && (
                      <Stack
                        width="100%"
                        paddingBottom="1em"
                        alignSelf="center"
                      >
                        <LinearProgress />
                      </Stack>
                    ))}
                  <Controller
                    name="otp"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.otp}
                        size="medium"
                        id="outlined-error-helper-text"
                        label="Enter OTP"
                        variant="outlined"
                        helperText={errors.otp?.message}
                        type="text"
                        fullWidth
                        margin="normal"
                        style={{ width: "100%!important" }}
                      />
                    )}
                  />
                </Box>
                <Button
                  size="large"
                  variant="contained"
                  type="submit"
                  fullWidth
                  sx={{
                    mt: "2em",
                    backgroundColor: "rgb(34, 47, 62)", // Custom background color
                    "&:hover": {
                      backgroundColor: "rgb(34 47 62 / 87%)" // Hover background color
                    }
                  }}
                >
                  Verify...
                </Button>
              </Stack>
              {isResendDisabled ? (
                <Stack>
                  <Typography variant="body2" alignSelf="end">
                    {`Resend OTP in ${formatTime(timer)} minutes`}
                  </Typography>
                </Stack>
              ) : (
                <Stack>
                  <Typography variant="body2" alignSelf="end">
                    Unable to receive OTP?
                    <Button variant="text" onClick={resendOTP}>
                      Resend OTP
                    </Button>
                  </Typography>
                </Stack>
              )}
            </Container>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
};

export default VerifyOtp;
