export const getAllServiceCategoriesQuery = `
query GetAllServiceCategories {
    getAllServiceCategories {
        id
        title
        icon
        description
    }
}
`;
