import React, { FC } from "react";
import { Box } from "@mui/material";
import { CarouselItemConfigType } from "./types/carouselItem";
import HomeCarouselItem from "./HomeCarouselItem";
import Carousel from "../Common/Carousel/Carousel";

interface HomeCarouselProps {
  carouseItemConfig: CarouselItemConfigType[];
  height?: string | number;
}

const HomeCarousel: FC<HomeCarouselProps> = ({ carouseItemConfig, height }) => {
  return (
    <Carousel
      height={height || "100%"}
      slideLength={carouseItemConfig.length}
      autoPlay
      autoPlayDelay={10000}
      width="100vw"
      showControllersOnHover
    >
      {carouseItemConfig.map((carouselItem) => (
        <Box
          key={carouselItem.headingTitle}
          sx={{
            flex: "0 0 100%", // Take up one full slide width
            display: "flex", // Optional: Customize slide layout
            justifyContent: "start",
            flexDirection: "row",
            width: "100%",
            height: "100%",
            margin: 0,
            maxWidth: "100%!important",
            position: "relative"
          }}
        >
          <HomeCarouselItem key={carouselItem.headingTitle} {...carouselItem} />
        </Box>
      ))}
    </Carousel>
  );
};

export default HomeCarousel;
